import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useEmailsUIContext } from "../EmailsUIContext";
import { t } from "i18next";

const prepareFilter = (queryParams, values) => {
	const { name, subject } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	// Filter by name
	filter.name = name !== "" ? name : "";
	// Filter by subject
	filter.subject = subject !== "" ? subject : "";
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function EmailsFilter() {
	// Emails UI Context
	const emailsUIContext = useEmailsUIContext();
	const emailsUIProps = useMemo(() => {
		return {
			queryParams: emailsUIContext.queryParams,
			setQueryParams: emailsUIContext.setQueryParams,
		};
	}, [emailsUIContext]);

	// queryParams, setQueryParams,
	const applyFilter = (values) => {
    	const newQueryParams = prepareFilter(emailsUIProps.queryParams, values);
    	if (!isEqual(newQueryParams, emailsUIProps.queryParams)) {
      		newQueryParams.pageNumber = 1;
      		// update list by queryParams
      		emailsUIProps.setQueryParams(newQueryParams);
    	}
  	};

	return (
    	<>
      		<Formik
        		initialValues={{
          			name: "",
          			subject: "",
        		}}
        		onSubmit={(values) => {
          			applyFilter(values);
        		}}
      		>
        	{({
        		values,
				handleSubmit,
        		handleBlur,
				setFieldValue,
        	}) => (
				<form onSubmit={handleSubmit} className="form form-label-right">
					<div className="form-group row">
						<div className="col-lg-1"></div>
						<div className="col-lg-4">
							<input
								type="text"
								className="form-control"
								name="name"
								placeholder={t('name')}
                  				onBlur={handleBlur}
								onChange={(e) => {
                    				setFieldValue("name", e.target.value);
                    				handleSubmit();
                  				}}
                  				value={values.name}
                			/>
              			</div>

						<div className="col-lg-4">
							<input
								type="text"
								className="form-control"
								name="subject"
								placeholder={t('subject')}
                  				onBlur={handleBlur}
								onChange={(e) => {
                    				setFieldValue("subject", e.target.value);
                    				handleSubmit();
                  				}}
                  				value={values.subject}
                			/>
              			</div>
            		</div>
          		</form>
        	)}
			</Formik>
    	</>
  	);
}
