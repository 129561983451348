import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RolesUIHelpers";

const RolesUIContext = createContext();

export function useRolesUIContext() {
	return useContext(RolesUIContext);
}

export const RolesUIConsumer = RolesUIContext.Consumer;

export function RolesUIProvider({rolesUIEvents, children}) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter);
	const [ids, setIds] = useState([]);
	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
			}

			return nextQueryParams;
		});
	}, []);

	const initRole = {
    	_id: undefined,
    	name: "",
    	is_active: 1,
    	permission : {
			dashboard : 1,
			users : 1,
			roles : 1,
			emails : 1,
			logs : 1,
			general_setting : 1,
    	}
	};

	const value = {
		queryParams,
		setQueryParamsBase,
		ids,
		setIds,
		setQueryParams,
		initRole,
		newRoleButtonClick: rolesUIEvents.newRoleButtonClick,
		openEditRoleDialog: rolesUIEvents.openEditRoleDialog,
		openDeleteRoleDialog: rolesUIEvents.openDeleteRoleDialog,
		openDeleteRolesDialog: rolesUIEvents.openDeleteRolesDialog,
		openFetchRolesDialog: rolesUIEvents.openFetchRolesDialog,
		openUpdateRolesStatusDialog: rolesUIEvents.openUpdateRolesStatusDialog
	};

	return <RolesUIContext.Provider value={value}>{children}</RolesUIContext.Provider>;
}