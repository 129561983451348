import * as requestFromServer from "./emailsCrud";
import {emailsSlice, callTypes} from "./emailsSlice";

const {actions} = emailsSlice;

export const fetchEmails = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findEmails(queryParams)
    .then(response => {
      const { totalCount, entities } = response.data;
      dispatch(actions.emailsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find emails";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchEmail = id => dispatch => {
  if (!id) {
    return dispatch(actions.emailFetched({ emailForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getEmailById(id)
    .then(response => {
      const email = response.data;
      dispatch(actions.emailFetched({ emailForEdit: email }));
    })
    .catch(error => {
      error.clientMessage = "Can't find email";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteEmail = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmail(id)
    .then(response => {
      dispatch(actions.emailDeleted({ id }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete email";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createEmail = emailForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createEmail(emailForCreation)
    .then(response => {
      const { email } = response.data;
      dispatch(actions.emailCreated({ email }));
    })
    .catch(error => {
      error.clientMessage = "Can't create email";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEmail = email => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateEmail(email)
    .then(() => {
      dispatch(actions.emailUpdated({ email }));
    })
    .catch(error => {
      error.clientMessage = "Can't update email";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateEmailsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForEmails(ids, status)
    .then(() => {
      dispatch(actions.emailsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update emails status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteEmails = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteEmails(ids)
    .then(() => {
      dispatch(actions.emailsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete emails";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};