/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { t } from "i18next";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">{t('dashboard')}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/users", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/users">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text">{t('users')}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/roles", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/roles">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Folder.svg")} />
            </span>
            <span className="menu-text">{t('roles')}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/emails", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/emails">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Mail.svg")} />
            </span>
            <span className="menu-text">{t('emails')}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/logs", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/logs">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Expand-arrows.svg")} />
            </span>
            <span className="menu-text">{t('logs')}</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive("/general-setting", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/general-setting">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")} />
            </span>
            <span className="menu-text">{t('general_settings')}</span>
          </NavLink>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
