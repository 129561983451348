export const UserStatusCssClasses = ["danger", "success"];
export const UserStatusTitles = ["Suspended", "Active", "Pending", ""];
export const UserTypeCssClasses = ["success", "primary", ""];
export const UserTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "_id", order: "asc" }];
export const sizePerPageList = [
	{ text: "10", value: 10 },
	{ text: "25", value: 25 },
	{ text: "50", value: 50 },
	{ text: "100", value: 100 },
	{ text: "200", value: 200 },
	{ text: "500", value: 500 },
];
export const initialFilter = {
	filter: {
    	name: "",
    	email: "",
		is_active: "",
  	},
	sortOrder: "desc", // asc||desc
	sortField: "_id",
	pageNumber: 1,
	pageSize: 10
};
