import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import { LoadingDialog } from "../../../../_metronic/_partials/controls";
// import { RoleEditDialog } from "./role-edit-dialog/RoleEditDialog";
import { DeleteDialog } from "../../../component/DeleteDialog";
import { RolesDeleteDialog } from "./roles-delete-dialog/RolesDeleteDialog";
import { RolesUpdateStateDialog } from "./roles-update-status-dialog/RolesUpdateStateDialog";
import { RolesUIProvider } from "./RolesUIContext";
import { RolesCard } from "./RolesCard";
import * as actions from "../../../../redux/roles/rolesActions";
import { t } from "i18next";

export default function RolesPage({ history }) {

  // Users Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.roles.actionsLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => { }, [isLoading, dispatch]);

  const deleteRole = (id) => {
    // server request for deleting user by id
    if (id) {
      dispatch(actions.deleteRole(id)).then(() => {
        // refresh list after deletion
        dispatch(actions.fetchRoles([]));
        // clear selections list
        // closing delete modal
        handleHide();
      }, [id, dispatch]);
    }
  };
  const handleHide = () => {
    history.push("/roles");
  }


  const rolesUIEvents = {
    newRoleButtonClick: () => {
      history.push("/roles/new");
    },
    openEditRoleDialog: (id) => {
      history.push(`/roles/${id}/edit`);
    },
    openDeleteRoleDialog: (id) => {
      history.push(`/roles/${id}/delete`);
    },
    openDeleteRolesDialog: () => {
      history.push(`/roles/deleteRoles`);
    },
    openFetchRolesDialog: () => {
      history.push(`/roles/fetch`);
    },
    openUpdateRolesStatusDialog: () => {
      history.push("/roles/updateStatus");
    }
  }

  return (
    <RolesUIProvider rolesUIEvents={rolesUIEvents}>
      <LoadingDialog isLoading={isLoading} text={t('loading')} />
      {/* <Route path="/roles/new">
        {({ match }) => (
          <RoleEditDialog
            show={match != null}
            onHide={handleHide}
          />
        )}
      </Route>
      <Route path="/roles/:id/edit">
        {({ match }) => (
          <RoleEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={handleHide}
          />
        )}
      </Route> */}
      <Route path="/roles/deleteRoles">
        {({ match }) => (
          <RolesDeleteDialog
            show={match != null}
            onHide={handleHide}
          />
        )}
      </Route>
      <Route path="/roles/:id/delete">
        {({ match }) => (
          <DeleteDialog
            title={t('role_delete')}
            text={t('are_you_sure_to_permanently_delete_this_role')}
            loadingText={t('deleting_role')}
            show={match != null}
            id={match && match.params.id}
            isLoading={isLoading}
            onHide={handleHide}
            deleteUser={deleteRole}
          />
        )}
      </Route>
      <Route path="/roles/updateStatus">
        {({ match }) => (
          <RolesUpdateStateDialog
            show={match != null}
            onHide={handleHide}
          />
        )}
      </Route>
      <RolesCard />
    </RolesUIProvider>
  );
}
