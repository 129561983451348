import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useUsersUIContext } from "../UsersUIContext";
import { useTranslation } from "react-i18next";

const prepareFilter = (queryParams, values) => {
	
	const { name, email, is_active } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	// Filter by name
	filter.name = name !== "" ? name : "";
	// Filter by email
	filter.email = email !== "" ? email : "";
	// Filter by status
	filter.is_active = is_active !== "" ? is_active : "";

	newQueryParams.filter = filter;
	return newQueryParams;
};

export function UsersFilter() {
	const { t } = useTranslation();
	// Users UI Context
	const usersUIContext = useUsersUIContext();
	const usersUIProps = useMemo(() => {
	return {
		queryParams: usersUIContext.queryParams,
		setQueryParams: usersUIContext.setQueryParams,
	};
	}, [usersUIContext]);

	// queryParams, setQueryParams,
	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(usersUIProps.queryParams, values);
		if (!isEqual(newQueryParams, usersUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			// update list by queryParams
			usersUIProps.setQueryParams(newQueryParams);
		}
	};

	return (
    	<>
		<Formik
			initialValues={{
				name: "",
				email: "",
				is_active: "",
			}}
        	onSubmit={(values) => {
          		applyFilter(values);
        	}}
      	>
        {({
			values,
			handleSubmit,
			handleBlur,
			handleChange,
			setFieldValue,
        }) => (
			<form onSubmit={handleSubmit} className="form form-label-right">
            	<div className="form-group row">
					<div className="col-lg-1"></div>
					<div className="col-lg-3">
						<input
							type="text"
							className="form-control"
							name="name"
							placeholder={t('name')}
							onBlur={handleBlur}
							onChange={(e) => {
								setFieldValue("name", e.target.value);
								handleSubmit();
							}}
							value={values.name}
						/>
					</div>

					<div className="col-lg-3">
						<input
							type="text"
							className="form-control"
							name="email"
							placeholder={t('email')}
							onBlur={handleBlur}
							onChange={(e) => {
								setFieldValue("email", e.target.value);
								handleSubmit();
							}}
							value={values.email}
						/>
					</div>

              		<div className="col-lg-2">
						<select
							className="form-control"
							placeholder={t('filter_by_status')}
							name="is_active"
							onBlur={handleBlur}
							onChange={(e) => {
								setFieldValue("is_active", e.target.value);
								handleSubmit();
							}}
							value={values.is_active}
						>
							<option value="">{t('all')}</option>
							<option value={true}>{t('active')}</option>
							<option value={false}>{t('in_active')}</option>
						</select>
						<small className="form-text text-muted">
							<b>{t('filter_by_status')}</b>
						</small>
					</div>
            	</div>
        	</form>
        )}
    	</Formik>
    	</>
	);
}
