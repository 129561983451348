// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/emails/emailsActions";
import {
	getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../EmailsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useEmailsUIContext } from "../EmailsUIContext";
import { createQueryString } from '../../../../Helpers';
import { EmailsFilter } from "../emails-filter/EmailsFilter";
import { t } from 'i18next';

export function EmailsTable() {
	// Emails UI Context
	const emailsUIContext = useEmailsUIContext();
	const emailsUIProps = useMemo(() => {
		return {
			ids: emailsUIContext.ids,
			setIds: emailsUIContext.setIds,
			queryParams: emailsUIContext.queryParams,
			setQueryParams: emailsUIContext.setQueryParams,
			openEditEmailDialog: emailsUIContext.openEditEmailDialog,
			openDeleteEmailDialog: emailsUIContext.openDeleteEmailDialog,
		};
	}, [emailsUIContext]);

  	// Getting curret state of emails list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({ currentState: state.emails }),
		shallowEqual
	);

	const { totalCount, entities, listLoading } = currentState;

	// Users Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// clear selections list
		emailsUIProps.setIds([]);
		// server call by queryParams
		dispatch(actions.fetchEmails(createQueryString(emailsUIProps.queryParams)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [emailsUIProps.queryParams, dispatch]);

	// Table columns
	const columns = [
		{
			dataField: "name",
			text: `${t('name')}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "subject",
			text: `${t('subject')}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "action",
			text: `${t('actions')}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				openEditEmailDialog: emailsUIProps.openEditEmailDialog,
				openDeleteEmailDialog: emailsUIProps.openDeleteEmailDialog,
			},
			classes: "text-right pr-0",
			headerClasses: "text-right pr-3",
			style: {
				minWidth: "100px",
			},
		},
	];

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: emailsUIProps.queryParams.pageSize,
		page: emailsUIProps.queryParams.pageNumber,
  	};

	return (
		<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)}>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
						>
						<EmailsFilter></EmailsFilter>
						<BootstrapTable
							wrapperClasses="table-responsive"
							bordered={false}
							classes="table table-head-custom table-vertical-center overflow-hidden"
							bootstrap4
							remote
							keyField="_id"
							data={entities === null ? [] : entities}
							columns={columns}
							defaultSorted={uiHelpers.defaultSorted}
							onTableChange={getHandlerTableChange(
								emailsUIProps.setQueryParams
							)}
							selectRow={getSelectRow({
								entities,
								ids: emailsUIProps.ids,
								setIds: emailsUIProps.setIds,
							})}
							{...paginationTableProps}
						>
							<PleaseWaitMessage entities={entities} />
							<NoRecordsFoundMessage entities={entities} />
						</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
		</>
  	);
}
