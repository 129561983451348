/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useTranslation } from "react-i18next";

export function SettingSideBar() {
	const { t } = useTranslation();
  return (
    <>
        <div
          className="flex-row-auto offcanvas-mobile "
          id="kt_profile_aside"
        >
			<div className="card card-custom card-stretch">
            	{/* begin::Body */}
				<div className="card-body pt-4">
              		{/* begin::Nav */}
					<div className="navi navi-bold navi-hover navi-active navi-link-rounded">
						<div className="navi-item mb-2">
						<NavLink
							to="/general-setting/security"
							className="navi-link py-4"
							activeClassName="active"
						>
							<span className="navi-icon mr-2">
							<span className="svg-icon">
								<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/General/User.svg"
								)}
								></SVG>{" "}
							</span>
							</span>
							<span className="navi-text font-size-lg">
							{t('security')}
							</span>
						</NavLink>
						</div>
						<div className="navi-item mb-2">
						<NavLink
							to="/general-setting/admin-media"
							className="navi-link py-4"
							activeClassName="active"
						>
							<span className="navi-icon mr-2">
							<span className="svg-icon">
								<SVG
								src={toAbsoluteUrl(
									"/media/svg/icons/General/User.svg"
								)}
								></SVG>{" "}
							</span>
							</span>
							<span className="navi-text font-size-lg">
							{t('admin_media')}
							</span>
						</NavLink>
						</div>
						<div className="navi-item mb-2">
							<NavLink
								to="/general-setting/email-setting"
								className="navi-link py-4"
								activeClassName="active"
							>
								<span className="navi-icon mr-2">
								<span className="svg-icon">
									<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Mail.svg"
									)}
									></SVG>{" "}
								</span>
								</span>
								<span className="navi-text font-size-lg">
								{t('email_setting')}
								</span>
							</NavLink>
						</div>
						<div className="navi-item mb-2">
							<NavLink
								to="/general-setting/test-email"
								className="navi-link py-4"
								activeClassName="active"
								>
								<span className="navi-icon mr-2">
								<span className="svg-icon">
									<SVG
									src={toAbsoluteUrl(
										"/media/svg/icons/Communication/Mail.svg"
									)}
									></SVG>{" "}
								</span>
								</span>
								<span className="navi-text font-size-lg">
								Test Mail
								</span>
							</NavLink>
						</div>
              		</div>
              		{/* end::Nav */}
            	</div>
            {/* end::Body */}
          </div>
        </div>
    </>
  );
}
