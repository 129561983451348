import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Route } from "react-router-dom";
import {LoadingDialog} from "../../../../_metronic/_partials/controls";
import { DeleteDialog } from "../../../component/DeleteDialog";
import { EmailsDeleteDialog } from "./emails-delete-dialog/EmailsDeleteDialog";
import { EmailsUpdateStateDialog } from "./emails-update-status-dialog/EmailsUpdateStateDialog";
import { EmailsUIProvider } from "./EmailsUIContext";
import { EmailsCard } from "./EmailsCard";
import * as actions from "../../../../redux/emails/emailsActions";
import { t } from "i18next";

export default function EmailsPage({ history }) {

  // Users Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.emails.actionsLoading }),
    shallowEqual
  );

  // looking for loading/dispatch
  useEffect(() => { }, [isLoading, dispatch]);

  const deleteEmail = (id) => {
    // server request for deleting user by id
    if (id) {
      dispatch(actions.deleteEmail(id)).then(() => {
        // refresh list after deletion
        dispatch(actions.fetchEmails([]));
        // clear selections list
        // closing delete modal
        handleHide();
      }, [id, dispatch]);
    }
  };
  const handleHide = () => {
    history.push("/emails");
  }

  const emailsUIEvents = {
    newEmailButtonClick: () => {
      history.push("/emails/new");
    },
    openEditEmailDialog: (id) => {
      history.push(`/emails/${id}/edit`);
    },
    openDeleteEmailDialog: (id) => {
      history.push(`/emails/${id}/delete`);
    },
    openDeleteEmailsDialog: () => {
      history.push(`/emails/deleteEmails`);
    },
    openUpdateEmailsStatusDialog: () => {
      history.push("/emails/updateStatus");
    }
  }

  return (
    <EmailsUIProvider emailsUIEvents={emailsUIEvents}>
      <LoadingDialog isLoading={isLoading} text={t('loading')} />
      {/* <Route path="/emails/new">
        {({ match }) => (
          <EmailEditDialog
            show={match != null}
           onHide={handleHide}
          />
        )}
      </Route>
      <Route path="/emails/:id/edit">
        {({ match }) => (
          <EmailEditDialog
            show={match != null}
            id={match && match.params.id}
           onHide={handleHide}
          />
        )}
      </Route> */}
      <Route path="/emails/deleteEmails">
        {({ match }) => (
          <EmailsDeleteDialog
            show={match != null}
           onHide={handleHide}
          />
        )}
      </Route>
      <Route path="/emails/:id/delete">
        {({ match }) => (
          <DeleteDialog
            title={t('email_template_delete')}
            text={t('are_you_sure_to_permanently_delete_this_email_template')}
            loadingText={t('deleting_template')}
            show={match != null}
            id={match && match.params.id}
            isLoading={isLoading}
            onHide={handleHide}
            deleteUser={deleteEmail}
          />
        )}
      </Route>
      <Route path="/emails/updateStatus">
        {({ match }) => (
          <EmailsUpdateStateDialog
            show={match != null}
           onHide={handleHide}
          />
        )}
      </Route>
      <EmailsCard />
    </EmailsUIProvider>
  );
}
