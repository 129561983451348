/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link,useLocation, useHistory } from "react-router-dom";
import { useSelector, shallowEqual, connect } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import * as auth from "../_redux/authRedux";
import { Input } from "../../../../_metronic/_partials/controls";
import * as requestFromServer from "../_redux/authCrud";
import { t } from "i18next";

function ResetPassword(props) {
    let history = useHistory();
	// Fields
    const search = useLocation().search;
    const token = new URLSearchParams(search).get('token');
	const [setLoading] = useState(false);
	const user = useSelector((state) => state.auth.user, shallowEqual);
	useEffect(() => {}, [user]);
	// Methods
	const resetPassword = (values, setStatus, setSubmitting) => {
        
        values.token = token;
		setLoading(true);
		requestFromServer.resetPassword(values)
			.then(response => {
                history.push('auth/login');
				//dispatch(props.setUser(response.data));
				setLoading(false);
			})
			.catch(error => {
				error.clientMessage = "Can't update password";
				setSubmitting(false);
				setStatus(error);
				setLoading(false);
			});
  	};

	// UI Helpers
	const initialValues = {
		password: "",
		cpassword: ""
	};

	const passwordSchema = Yup.object().shape({
		password: Yup.string().required(`${t('new_password_is_required')}`),
		cpassword: Yup.string()
			.required(`${t('password_confirmation_is_required')}`)
			.when("password", {
				is: (val) => (val && val.length > 0 ? true : false),
				then: Yup.string().oneOf(
					[Yup.ref("password")],
					`${t('password_and_confirm_password_didnt_match')}`
				),
			}),
	});

	return (
    	<>
			<Formik
				enableReinitialize={true}
				validationSchema={passwordSchema}
				initialValues={initialValues}
				onSubmit={(values, {setStatus, setSubmitting}) => {
					resetPassword(values, setStatus, setSubmitting);
				}}
			>
				{({ handleSubmit, touched, errors }) => (
                    <div className="login-form login-forgot" style={{ display: "block" }}>
                        <div className="text-center mb-10 mb-lg-20">
                            <h3 className="font-size-h1">Reset Password</h3>
                            <div className="text-muted font-weight-bold">
                            </div>
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                            <Field
                                type="password"
                                placeholder={t('new_password')}
                                className={`form-control form-control-lg form-control-solid`}
                                name="password"
                                component={Input}
                                withFeedbackLabel={false}
                            />
                            {errors.password ? (
                                <div className="invalid-feedback" style={{display: 'block'}}>{errors.password}</div>
                            ) : null}
                        </div>
                        <div className="form-group fv-plugins-icon-container">
                            <Field
                                type="password"
                                placeholder={t('verify_password')}
                                className={`form-control form-control-lg form-control-solid`}
                                name="cpassword"
                                component={Input}
                                withFeedbackLabel={false}
                            />
                            {touched.cpassword && errors.cpassword ? (
                                <div className="invalid-feedback" style={{display: 'block'}}>{errors.cpassword}</div>
                            ) : null}
                        </div>
                        <div className="form-group d-flex flex-wrap flex-center">
                        <button
                                type="submit"
                                onClick={() => handleSubmit()}
                                className="btn btn-success mr-2"						
                            >
                                {t('save_changes')}
                            </button>
                            <Link
                                to="/auth"
                                className="btn btn-secondary"
                            >
                                {t('cancel')}
                            </Link>
                        </div>
                    </div>
				)}
			</Formik>
		</>
	);
}
export default connect(null, auth.actions)(ResetPassword);
