export const RoleStatusCssClasses = ["danger", "success"];
export const RoleStatusTitles = ["Suspended", "Active", "Pending", ""];
export const RoleTypeCssClasses = ["success", "primary", ""];
export const RoleTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "_id", order: "asc" }];
export const sizePerPageList = [
	{ text: "3", value: 3 },
	{ text: "5", value: 5 },
	{ text: "10", value: 10 }
];
export const initialFilter = {
	filter: {
		name: "",
		is_active: ""
	},
	sortOrder: "asc", // asc||desc
	sortField: "_id",
	pageNumber: 1,
	pageSize: 10
};
