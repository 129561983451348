/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../../redux/users/usersActions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { UserEditForm } from "./UserEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";


export function UserEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const initUser = {
		_id: undefined,
		name: "",
		email: "",
		phone_no: "",
		password:"",
		avatar: "",
		is_active: 1,
  };
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual
  );

  const [searchField, setSearchField] = useState('');

  useEffect(() => {
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New User";
    if (userForEdit && id) {
      console.log(userForEdit)
      _title = `Edit User`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userForEdit, id]);

  const handleFileUpload = (event) => {
    setSearchField(event.currentTarget.files[0])
  }
  const saveUser = (user) => {
    let data = new FormData();
    Object.keys(user).forEach(key => {
      if (key !== 'avatar') {
        if(typeof  user[key] !== 'undefined'){
            data.append(key, user[key]);
        }
      }
    });
    data.append('avatar',searchField)
    if (!id) {
      // server request for creating user
      dispatch(actions.createUser(data)).then(() => backToUsersList());
    } else {
      // server request for updating user
      dispatch(actions.updateUser(data)).then(() => backToUsersList());
    }
  };

  const backToUsersList = () => {
    history.push(`/users`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
            <UserEditForm
              actionsLoading={actionsLoading}
              user={userForEdit || initUser}
              saveUser={saveUser}
              handleFileUpload={handleFileUpload}
              backToUsersList = {backToUsersList}
            />
        </div>
      </CardBody>
    </Card>
  );
}
