import axios from "axios";
axios.defaults.headers.common['authorization'] = localStorage.getItem('authToken');
axios.defaults.headers.put['Content-Type'] = "multipart/form-data";
const config = { headers: { 'Content-Type': 'multipart/form-data' } };
export const UPDATE_PROFILE_URL = `${process.env.REACT_APP_API_URL}auth/update`;
export const USERS_URL = `${process.env.REACT_APP_API_URL}users`;
export const LOGS_URL = `${process.env.REACT_APP_API_URL}logs`;

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
	return axios.post(USERS_URL, user ,config);
}

// READ
export function getAllUsers() {
	return axios.get(USERS_URL);
}

export function getAllLogs() {
	return axios.get(LOGS_URL);
}

export function getUserById(userId) {
	return axios.get(`${USERS_URL}/${userId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

export function findUsers(queryParams) {
	return axios.get(`${USERS_URL}?` + queryParams);
}

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
	let id = user.get('_id');
	return axios.patch(`${USERS_URL}/${id}`, user);
}

// UPDATE Status
export function updateStatusForUsers(ids, status) {
	return axios.post(`${USERS_URL}/updateStatusForUsers`, {
    	ids,
    	status
  	});
}

// DELETE => delete the user from the server
export function deleteUser(userId) {
	return axios.delete(`${USERS_URL}/${userId}`);
}

// DELETE Users by ids
export function deleteUsers(ids) {
	return axios.post(`${USERS_URL}/delete`, { ids });
}

//update profile
export function updateProfile(user) {
	return axios.post(`${UPDATE_PROFILE_URL}`, user);
}
