import axios from "axios";
axios.defaults.headers.common['authorization'] = localStorage.getItem('authToken');
axios.defaults.headers.put['Content-Type'] = "multipart/form-data";
const config = { headers: { 'Content-Type': 'multipart/form-data' } };
export const SETTINGS_URL = `${process.env.REACT_APP_API_URL}settings`;
export const TEST_MAIL_URL = `${process.env.REACT_APP_API_URL}settings/send_test_mail`;

// CREATE =>  POST: add a new setting to the server
export function createSetting(setting) {
  return axios.post(SETTINGS_URL, { setting },config);
}
export function createSettingLogo(setting) {
  return axios.post(SETTINGS_URL,  setting,config );
}

export function getSettingById(settingId) {
	return axios.get(`${SETTINGS_URL}/${settingId}`);
}
// UPDATE => PUT: update the setting on the server
export function updateSetting(setting) {
  return axios.patch(`${SETTINGS_URL}/${setting.id}`, { setting });
}
export function updateSettingLogo(setting) {
  return axios.patch(`${SETTINGS_URL}/${setting._id}`, { setting});
}

export function getSetting() {
  return axios.get(SETTINGS_URL);
}

export function createOrUpdateSetting(setting) {
	return axios.post(SETTINGS_URL, { setting });
}

export function sendTestMail(setting) {
	return axios.post(TEST_MAIL_URL, { setting });
}


