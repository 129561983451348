import * as requestFromServer from "./logsCrud";
import {logsSlice, callTypes} from "./logsSlice";

const {actions} = logsSlice;

export const fetchLogs = () => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllLogs()
    .then(response => {
       const logs  = response.data.data;
      dispatch(actions.logsFetched({ logs }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Logs";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};