import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { DashboardPage } from "./pages/DashboardPage";
import UsersPage from "./modules/Users/pages/UsersPage";
import { UserEdit } from "./modules/Users/pages/user-edit-dialog/UserEdit";
import { RoleEdit } from "./modules/Roles/pages/role-edit-dialog/RoleEdit";
import { EmailEdit } from "./modules/Email/pages/email-edit-dialog/EmailEdit";
import UserProfilePage from "./modules/UserProfile/UserProfilePage";
import GeneralSettingPage from "./modules/GeneralSettings/GeneralSettingPage";
import RolesPage from "./modules/Roles/pages/RolesPage";
import EmailPage from "./modules/Email/pages/EmailsPage";
import LogPage from "./pages/Logs/LogPage";

export default function BasePage() {
	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{
					/* Redirect from root URL to /dashboard. */
					<Redirect exact from="/" to="/dashboard" />
				}
				<ContentRoute path="/dashboard" component={DashboardPage} />
				<ContentRoute path="/builder" component={BuilderPage} />
				<ContentRoute path="/users/new" component={UserEdit} />
				<ContentRoute
				path="/users/:id/edit"
				component={UserEdit}
				/>
				<Route path="/users" component={UsersPage} />
				<ContentRoute path="/roles/new" component={RoleEdit} />
				<ContentRoute
				path="/roles/:id/edit"
				component={RoleEdit}
				/>
				<Route path="/roles" component={RolesPage} />

				<ContentRoute path="/emails/new" component={EmailEdit} />
				<ContentRoute
				path="/emails/:id/edit"
				component={EmailEdit}
				/>
				<Route path="/emails" component={EmailPage} />

				<Route path="/logs" component={LogPage} />
				<Route path="/user-profile" component={UserProfilePage} />
				<Route path="/general-setting" component={GeneralSettingPage} />
				<Redirect to="error/error-v1" />
			</Switch>
		</Suspense>
	);
}