/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import * as auth from "../Auth";
import * as actions from "../../../redux/settings/settingsActions";
import {
	Input,
	Select,
} from "../../../_metronic/_partials/controls";
import { useTranslation } from "react-i18next";

function TestEmail(props) {
	const { t } = useTranslation();
  	// Settings Redux state
  	const dispatch = useDispatch();
	useEffect(() => {
		// server call for getting Setting by id
		dispatch(actions.fetchSetting());
	}, [dispatch]);

	const { actionsLoading } = useSelector(
	    (state) => ({
			actionsLoading: state.settings.actionsLoading
		})
  	);

	// server request for saving setting
  	const sendTestEmail = (setting) => {
		dispatch(actions.sendTestMail(setting));
  	};
  
	// UI Helpers
	const initialValues = {
		email: "",
	};

  	const emailSettingSchema = Yup.object().shape({
		email: Yup.string()
		  .min(3, `${t('minimum_3_symbols')}`)
		  .max(50, `${t('maximum_50_symbols')}`)
		  .email(`${t('wrong_email_format')}`)
		  .required(`${t('email_send_from_is_required')}`)
  	});
	
	return (
		<>
		  <Formik
			enableReinitialize={true}
			validationSchema={emailSettingSchema}
			initialValues={initialValues}
			onSubmit={(values) => {
				sendTestEmail(values);
			}}
		  >
			{({ handleSubmit, touched, errors }) => (
			  <>
			  	<div className="card card-custom">
					{/* begin::Header */}
					<div className="card-header py-3">
						<div className="card-title align-items-start flex-column">
						<h3 className="card-label font-weight-bolder text-dark">
							{t("test_mail")}
						</h3>
						</div>
						<div className="card-toolbar">
						<button
							type="submit"
							onClick={() => handleSubmit()}
							className="btn btn-success mr-2"						
						>
							{t("save_changes")}
						</button>
						<Link
							to="/general-setting/general"
							className="btn btn-secondary"
						>
							{t("cancel")}
						</Link>
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Form */}
					<div className="form">
						<div className="card-body">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							{/* E-mail send from */}
							<div className="form-group row">
								<label className="col-lg-3 col-form-label">
									<span className="lblSpan">{t("email_send_from")}</span>
									<span style={{ color: 'red' }}>&nbsp;*</span>
								</label>
								<div className="col-lg-6 input-group">
									<div className="input-group-prepend">
										<span className="input-group-text">
											<i className="fa fa-envelope" aria-hidden="true"></i>
										</span>
									</div>
									<Field
										type="email"
										name="email"
										component={Input}
										placeholder="Email"
										withFeedbackLabel={false}
									/>
									{touched.name && errors.name ? (
										<div className="invalid-feedback">
											{errors.name}
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					{/* end::Form */}
				</div>
			  </>
			)}
		  </Formik>
		</>
	);
}

export default connect(null, auth.actions)(TestEmail);
