import { createSlice } from "@reduxjs/toolkit";

const initialSettingsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  settingForEdit: undefined,
  lastError: null,
  logs: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getSettingById
    settingFetched: (state, action) => {
      state.actionsLoading = false;
      state.settingForEdit = action.payload.settingForEdit;
      state.error = null;
    },
    // findSettings
    settingsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createSetting
    settingCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.setting);
    },
    // updateSetting
    settingUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity.id === action.payload.setting.id) {
          return action.payload.setting;
        }
        return entity;
      });
    },

  }
});
