// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../_metronic/_partials/controls";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { t } from "i18next";

// Validation schema
const EmailEditSchema = Yup.object().shape({
  name: Yup.string()
	.min(3, `${t('minimum_3_symbols')}`)
	.max(50, `${t('maximum_50_symbols')}`)
	.required(`${t('name_is_required')}`),
  subject: Yup.string()
	.min(3, `${t('minimum_3_symbols')}`)
	.max(50, `${t('maximum_50_symbols')}`)
	.required(`${t('subject_is_required')}`),
});

let content = "";

const inputHandler = (event, editor) => {
    content = editor.getData();
};

export function EmailEditForm({
  saveEmail,
  email,
  actionsLoading,
  backToEmailList,
}) {
  return (
	<>
	  <Formik
		enableReinitialize={true}
		initialValues={email}
		validationSchema={EmailEditSchema}
		onSubmit={(values) => {
			const fd = new FormData();
		    fd.append("content", content);
		    values.content = content;
		  	saveEmail(values);
		}}
	  >
		{({ handleSubmit }) => (
		  <>
			<Modal.Body className="overlay overlay-block cursor-default">
			  {actionsLoading && (
				<div className="overlay-layer bg-transparent">
				  <div className="spinner spinner-lg spinner-success" />
				</div>
			  )}
			  <Form className="form form-label-right">
				<div className="form-group row">
				  	{/* Name */}
				  	<div className="col-lg-8">
						<Field
						  name="name"
						  component={Input}
						  placeholder={t('name')}
						  label={t('name')}
						  withFeedbackLabel={false}
						/>
				  	</div>
				</div>
				<div className="form-group row">
				  	{/* Subject */}
				  	<div className="col-lg-8">
						<Field
						  name="subject"
						  component={Input}
						  placeholder={t('subject')}
						  label={t('subject_mail')}
						  withFeedbackLabel={false}
						/>
				  	</div>
				</div>
				<div className="form-group row">
				  	{/* Type */}
				  	<div className="col-lg-8">
						<Field
						  name="type"
						  component={Input}
						  label={t('trigger_by')}
						  withFeedbackLabel="false"
						  disabled="disabled"
						/>
				  	</div>
				</div>

				<div className="form-group row">
				  	{/* BCC */}
				  	<div className="col-lg-8">
						<Field
						  name="bcc"
						  component={Input}
						  label={t('bcc_mailaddress')}
						  withFeedbackLabel={false}
						/>
				  	</div>
				</div>

				<div className="form-group row">
				  	{/* CKEditor */}
					<CKEditor
	                    editor={ ClassicEditor }
	                    data={email.content}
	                    name="content"
	                    label={t('email_text')}
	                    onReady={ editor => {
	                        // You can store the "editor" and use when it is needed.
	                        editor.editing.view.change((writer) => {
						        writer.setStyle(
						            "height",
						            "200px",
						            editor.editing.view.document.getRoot()
						        );
					        });
	                    }}
	                    onChange={inputHandler}
	                />
		        </div>

                <div className="form-group row">
				  	{/* Used variables */}
				  	<div className="col-lg-8">
						<Field
						  name="mailtags"
						  component={Input}
						  label={t('used_variables')}
						  withFeedbackLabel={false}
						/>
				  	</div>
				</div>
				
			  </Form>
			</Modal.Body>
			<Modal.Footer>
			  <button
				type="button"
				onClick={backToEmailList}
				className="btn btn-light btn-elevate"
			  >
				{t('cancel')}
			  </button>
			  <> </>
			  <button
				type="submit"
				onClick={() => handleSubmit()}
				className="btn btn-primary btn-elevate"
			  >
				{t('Save')}
			  </button>
			</Modal.Footer>
		  </>
		)}
	  </Formik>
	</>
  );
}
