import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {usersSlice} from "./users/usersSlice";
import {rolesSlice} from "./roles/rolesSlice";
import {emailsSlice} from "./emails/emailsSlice";
import {logsSlice} from "./logs/logsSlice";
import {settingsSlice} from "./settings/settingsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: usersSlice.reducer,
  roles: rolesSlice.reducer,
  emails: emailsSlice.reducer,
  logs: logsSlice.reducer,
  settings: settingsSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}