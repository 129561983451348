import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import * as auth from "../Auth";
import * as actions from "../../../redux/settings/settingsActions";
import {
	Input,
	Select,
} from "../../../_metronic/_partials/controls";
import { useTranslation } from "react-i18next";

function Security(props) {
	const { t } = useTranslation();
	// Settings Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// server call for getting Setting by id
		dispatch(actions.fetchSetting());
	}, [dispatch]);

	const { actionsLoading, settingForEdit } = useSelector(
		(state) => ({
			actionsLoading: state.settings.actionsLoading, 
			settingForEdit: state.settings.settingForEdit 
		})
	);

	// server request for saving setting
	const saveSetting = (setting) => {
		dispatch(actions.createOrUpdateSetting(setting));
  	};
	// UI Helpers
	const initialValues = {
		password_expire_value : (settingForEdit) ? settingForEdit.password_expire_value : 4,
    	password_expire_unit : (settingForEdit) ? settingForEdit.password_expire_unit : 'months',
		session_expire_value : (settingForEdit) ? settingForEdit.session_expire_value : 4,
		session_expire_unit : (settingForEdit) ? settingForEdit.session_expire_unit : 'days',
		password_link_expire_value : (settingForEdit) ? settingForEdit.password_link_expire_value : 4,
		password_link_expire_unit : (settingForEdit) ? settingForEdit.password_link_expire_unit : 'days',
		id: (settingForEdit) ? settingForEdit._id : "",
	}

	return (
		<>
		  <Formik
			enableReinitialize={true}
			initialValues={initialValues}
			onSubmit={(values) => {
				saveSetting(values);
			}}
		  >
			{({ handleSubmit }) => (
			  <>
			  	<div className="card card-custom card-stretch">
					
					{/* begin::Header */}
					<div className="card-header py-3">
						<div className="card-title align-items-start flex-column">
							<h3 className="card-label font-weight-bolder text-dark">
							{t('security')}
							</h3>
						</div>
						<div className="card-toolbar">
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-success mr-2"						
							>
								{t('save_changes')}
							</button>
							<Link
								to="/general-setting/general"
								className="btn btn-secondary"
							>
								{t('cancel')}
							</Link>
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Form */}
					<div className="form">
						<div className="card-body">
							{actionsLoading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<div className="row">
            					<label className="col-xl-3"></label>
            					<div className="col-lg-9 col-xl-6"></div>
          					</div>
							<div className="row">
            					<div className="col-lg-12 col-xl-12">
              						<h5 className="font-weight-bold mt-10 mb-6">{t('password_setting_desc')}</h5>
            					</div>
          					</div>
							<div className="form-group row">
            					<label className="col-xl-3 col-lg-3 col-form-label">
								{t('expire_every')}:
            					</label>
            					<div className="col-lg-3 col-xl-3">
            						<div className="input-group input-group-lg input-group-solid">
            							<Field
                  							className="form-control form-control-lg form-control-solid"
                  							name="password_expire_value"
                  							type="number"
											component={Input}
                						/>  
              						</div>
            					</div>
            					<div className="col-lg-3 col-xl-3">
                					<Select name="password_expire_unit" className="form-control">
                  						<option value="months">{t('months')}</option>
                  						<option value="years">{t('years')}</option>
                					</Select>
            					</div>
          					</div>

							<div className="row">
            					<div className="col-lg-12 col-xl-12">
              						<h5 className="font-weight-bold mt-10 mb-6">{t('remember_session')}: </h5>
            					</div>
          					</div>
							<div className="form-group row">
            					<label className="col-xl-3 col-lg-3 col-form-label">
								{t('remember_time_out')}:
            					</label>
            					<div className="col-lg-3 col-xl-3">
              						<div className="input-group input-group-lg input-group-solid">
										<Field
											className={`form-control form-control-lg form-control-solid`}
											name="session_expire_value"
											type="number"
											component={Input}
										/>
              						</div>
            					</div>
            					<div className="col-lg-3 col-xl-3">
									<Select name="session_expire_unit" className="form-control">
                  						<option value="hour">{t('hours')}</option>
                  						<option value="minutes">{t('minutes')}</option>
										<option value="days">{t('days')}</option>
                  						<option value="months">{t('months')}</option>
                					</Select>
            					</div>
          					</div>

							<div className="row">
            					<div className="col-lg-12 col-xl-12">
              						<h5 className="font-weight-bold mt-10 mb-6">{t('validity_link')}:</h5>
            					</div>
          					</div>
							
							<div className="form-group row">
            					<label className="col-xl-3 col-lg-3 col-form-label">
								{t('remember_time_out')}:
            					</label>
            					<div className="col-lg-3 col-xl-3">
              						<div className="input-group input-group-lg input-group-solid">
                						<Field
                  							className={`form-control form-control-lg form-control-solid`}
                  							name="password_link_expire_value"
                  							type="number"
											component={Input}
                						/>
              						</div>
            					</div>
            					<div className="col-lg-3 col-xl-3">
									<Select name="password_link_expire_unit" className="form-control">
										<option value="hour">{t('hours')}</option>
                  						<option value="minutes">{t('minutes')}</option>
										<option value="days">{t('days')}</option>
                  						<option value="months">{t('months')}</option>
									</Select>
            					</div>
          					</div>
						</div>
					</div>
					{/* end::Form */}
				</div>
			  </>
			)}
		  </Formik>
		</>
	);  
}

export default connect(null, auth.actions)(Security);
