// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/roles/rolesActions";
import {
	getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../RolesUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useRolesUIContext } from "../RolesUIContext";
import { createQueryString } from '../../../../Helpers';
import { RolesFilter } from "../roles-filter/RolesFilter";
import { useTranslation } from "react-i18next";

export function RolesTable() {
	const { t } = useTranslation();
	// Users UI Context
	const rolesUIContext = useRolesUIContext();
	const rolesUIProps = useMemo(() => {
		return {
			ids: rolesUIContext.ids,
			setIds: rolesUIContext.setIds,
			queryParams: rolesUIContext.queryParams,
			setQueryParams: rolesUIContext.setQueryParams,
			openEditRoleDialog: rolesUIContext.openEditRoleDialog,
			openDeleteRoleDialog: rolesUIContext.openDeleteRoleDialog,
		};
	}, [rolesUIContext]);

	// Getting curret state of roles list from store (Redux)
	const { currentState } = useSelector(
		(state) => ({ currentState: state.roles }),
		shallowEqual
	);

	const { totalCount, entities, listLoading } = currentState;

	// Users Redux state
	const dispatch = useDispatch();
	useEffect(() => {
		// clear selections list
		rolesUIProps.setIds([]);
		// server call by queryParams
		dispatch(actions.fetchRoles(createQueryString(rolesUIProps.queryParams)));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rolesUIProps.queryParams, dispatch]);

	// Table columns
	const columns = [
		{
			dataField: "name",
			text: `${t('name')}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
		{
			dataField: "is_active",
			text: `${t('active')}`,
			sort: true,
			sortCaret: sortCaret,
			formatter: columnFormatters.StatusColumnFormatter,
			headerSortingClasses,
		},
		{
			dataField: "action",
			text: `${t('actions')}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				openEditRoleDialog: rolesUIProps.openEditRoleDialog,
				openDeleteRoleDialog: rolesUIProps.openDeleteRoleDialog,
			},
			classes: "text-right pr-0",
			headerClasses: "text-right pr-3",
			style: {
				minWidth: "100px",
			},
		},
	];

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: rolesUIProps.queryParams.pageSize,
		page: rolesUIProps.queryParams.pageNumber,
	};

	return (
    	<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)}>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
						>
							<RolesFilter></RolesFilter>
							<BootstrapTable
								wrapperClasses="table-responsive"
								bordered={false}
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								remote
								keyField="_id"
								data={entities === null ? [] : entities}
								columns={columns}
								defaultSorted={uiHelpers.defaultSorted}
								onTableChange={getHandlerTableChange(
									rolesUIProps.setQueryParams
								)}
								selectRow={getSelectRow({
									entities,
									ids: rolesUIProps.ids,
									setIds: rolesUIProps.setIds,
								})}
								{...paginationTableProps}
							>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
    	</>
	);
}
