/**
 * Helper file to write all common functions.
 * */

export function createQueryString(objectData) {
	return Object.keys(objectData).map(key => {
		  let val = objectData[key]
		  if (val !== null && typeof val === 'object') val = createQueryString(val)
		  return `${key}=${encodeURIComponent(`${val}`.replace(/\s/g, '_'))}`
	}).join('&')
}