import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSubheader } from "../../../_metronic/layout";
import Security from "./Security";
import AdminMedia from "./AdminMedia";
import EmailSetting from "./EmailSetting";
import TestEmail from "./TestEmail";
import { SettingSideBar } from "./components/SettingSideBar";

export default function GeneralSettingPage() {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Email Setting");
  return (
    <div className="d-flex flex-row">
      <SettingSideBar></SettingSideBar>
      <div className="flex-row-fluid ml-lg-8">
        <Switch>
          <Redirect
            from="/general-setting"
            exact={true}
            to="/general-setting/security"
          />
          <Route
            path="/general-setting/security"
            component={Security}
          />
          <Route
            path="/general-setting/admin-media"
            component={AdminMedia}
          />
          <Route
            path="/general-setting/email-setting"
            component={EmailSetting}
          />
          <Route
            path="/general-setting/test-email"
            component={TestEmail}
          />
        </Switch>
      </div>
    </div>
  );
}
