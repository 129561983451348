import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { EmailsTable } from "./emails-table/EmailsTable";
import { EmailsGrouping } from "./emails-grouping/EmailsGrouping";
import { useEmailsUIContext } from "./EmailsUIContext";
import { t } from "i18next";

export function EmailsCard() {
  const emailsUIContext = useEmailsUIContext();
  const emailsUIProps = useMemo(() => {
    return {
      ids: emailsUIContext.ids,
      newEmailButtonClick: emailsUIContext.newEmailButtonClick,
    };
  }, [emailsUIContext]);

  return (
    <Card>
      <CardHeader title={t('emails_list')}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={emailsUIProps.newEmailButtonClick}
          >
            {t('new_email')}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <EmailsTable /> */}
        {emailsUIProps.ids.length > 0 && <EmailsGrouping />}
        <EmailsTable />
      </CardBody>
    </Card>
  );
}
