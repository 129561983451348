export const EmailStatusCssClasses = ["danger", "success"];
export const EmailStatusTitles = ["Suspended", "Active", "Pending", ""];
export const EmailTypeCssClasses = ["success", "primary", ""];
export const EmailTypeTitles = ["Business", "Individual", ""];
export const defaultSorted = [{ dataField: "_id", order: "asc" }];
export const sizePerPageList = [
	{ text: "3", value: 3 },
	{ text: "5", value: 5 },
	{ text: "10", value: 10 }
];
export const initialFilter = {
	filter: {
		name: "",
		subject: "",
	},
	sortOrder: "desc", // asc||desc
	sortField: "_id",
	pageNumber: 1,
	pageSize: 10
};
