/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../../redux/roles/rolesActions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { RoleEditForm } from "./RoleEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";


export function RoleEdit({
  history,
  match: {
    params: { id },
  },
}) {
  const initRole = {
    _id: undefined,
    name: "",
    is_active: 1,
    permission : {
    dashboard : 1,
    users : 1,
    roles : 1,
    emails : 1,
    logs : 1,
    general_setting : 1,
    }
};
  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [title, setTitle] = useState("");

  // Roles Redux state
	const dispatch = useDispatch();
	const { actionsLoading, roleForEdit } = useSelector(
		(state) => ({
			actionsLoading: state.roles.actionsLoading,
			roleForEdit: state.roles.roleForEdit,
		}),
		shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchRole(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Role";
    if (roleForEdit && id) {
      console.log(roleForEdit)
      _title = `Edit Role`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleForEdit, id]);

  // server request for saving role
  const saveRole = (role) => {
    if (!id) {
    // server request for creating role
    dispatch(actions.createRole(role)).then(() => backToRoleList());
    } else {
        // server request for updating role
        dispatch(actions.updateRole(role)).then(() => backToRoleList());
    }
  };

  const roleData = {
    _id : (roleForEdit) ? roleForEdit._id : "",
    name : (roleForEdit) ? roleForEdit.name : "",
    is_active : (roleForEdit) ? roleForEdit.is_active : 0,
    permission : (roleForEdit) ? (roleForEdit.permission) ? roleForEdit.permission : {} : {},
  };

  const backToRoleList = () => {
    history.push(`/roles`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
            <RoleEditForm
              actionsLoading={actionsLoading}
              role={roleData || initRole}
              saveRole={saveRole}
              backToRoleList = {backToRoleList}
            />
        </div>
      </CardBody>
    </Card>
  );
}
