import React, { useEffect, useState, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  EmailStatusCssClasses,
  EmailStatusTitles,
} from "../EmailsUIHelpers";
import * as actions from "../../../../../redux/emails/emailsActions";
import { useEmailsUIContext } from "../EmailsUIContext";
import { t } from "i18next";

const selectedEmails = (entities, ids) => {
  const _emails = [];
  ids.forEach((id) => {
    const email = entities.find((el) => el.id === id);
    if (email) {
      _emails.push(email);
    }
  });
  return _emails;
};

export function EmailsUpdateStateDialog({ show, onHide }) {
  // Emails UI Context
  const emailsUIContext = useEmailsUIContext();
  const emailsUIProps = useMemo(() => {
    return {
      ids: emailsUIContext.ids,
      setIds: emailsUIContext.setIds,
      queryParams: emailsUIContext.queryParams,
    };
  }, [emailsUIContext]);

  // Emails Redux state
  const { emails, isLoading } = useSelector(
    (state) => ({
      emails: selectedEmails(
        state.emails.entities,
        emailsUIProps.ids
      ),
      isLoading: state.emails.actionsLoading,
    }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!emailsUIProps.ids || emailsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsUIProps.ids]);

  const [status, setStatus] = useState(0);

  const dispatch = useDispatch();
  const updateStatus = () => {
    // server request for update emails status by selected ids
    dispatch(actions.updateEmailsStatus(emailsUIProps.ids, status)).then(
      () => {
        // refresh list after deletion
        dispatch(actions.fetchEmails(emailsUIProps.queryParams)).then(
          () => {
            // clear selections list
            emailsUIProps.setIds([]);
            // closing delete modal
            onHide();
          }
        );
      }
    );
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {t('status_has_been_updated_for_selected_emails')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overlay overlay-block cursor-default">
        {/*begin::Loading*/}
        {isLoading && (
          <div className="overlay-layer">
            <div className="spinner spinner-lg spinner-primary" />
          </div>
        )}
        {/*end::Loading*/}
        <table className="table table table-head-custom table-vertical-center overflow-hidden">
          <thead>
            <tr>
              <th>{t('ID')}</th>
              <th>{t('STATUS')}</th>
              <th>{t('CUSTOMER')}</th>
            </tr>
          </thead>
          <tbody>
            {emails.map((email) => (
              <tr key={`id${email.id}`}>
                <td>{email.id}</td>
                <td>
                  <span
                    className={`label label-lg label-light-${
                      EmailStatusCssClasses[email.status]
                    } label-inline`}
                  >
                    {" "}
                    {EmailStatusTitles[email.status]}
                  </span>
                </td>
                <td>
                  <span className="ml-3">
                    {email.lastName}, {email.firstName}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer className="form">
        <div className="form-group">
          <select
            className="form-control"
            value={status}
            onChange={(e) => setStatus(+e.target.value)}
          >
            <option value="0">{t('suspended')}</option>
            <option value="1">{t('active')}</option>
            <option value="2">{t('pending')}</option>
          </select>
        </div>
        <div className="form-group">
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate mr-3"
          >
            {t('cancel')}
          </button>
          <button
            type="button"
            onClick={updateStatus}
            className="btn btn-primary btn-elevate"
          >
            {t('update_status')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
