/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Redirect,useLocation } from "react-router-dom";
import { useSelector, shallowEqual, connect } from "react-redux";
import * as auth from "../_redux/authRedux";
import * as requestFromServer from "../_redux/authCrud";

function ActivatedAccount(props) {
	// Fields
	const user = useSelector((state) => state.auth.user, shallowEqual);
	useEffect(() => {}, [user]);
	// Methods
	const ActivatedAccount = () => {
        const search = useLocation().search;
        const token = new URLSearchParams(search).get('token');
        console.log(token);
        requestFromServer.activeAccount({token:token})
        .then(response => {

        })
        .catch(error => {
            error.clientMessage = "Something went wrong.";
        });
  	};
    ActivatedAccount()
	return (
    	<>
			<Redirect to={{
              pathname: '/auth/login', 
              state: { message: 'Account activated successfully.'}
            }}  />
		</>
	);
}
export default connect(null, auth.actions)(ActivatedAccount);
