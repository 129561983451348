import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { UsersFilter } from "./users-filter/UsersFilter";
import { UsersTable } from "./users-table/UersTable";
import { UsersGrouping } from "./users-grouping/UsersGrouping";
import { useUsersUIContext } from "./UsersUIContext";
import { useTranslation } from "react-i18next";

export function UsersCard() {
  const { t } = useTranslation();
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      newUserButtonClick: usersUIContext.newUserButtonClick,
      openEditUserDialog: usersUIContext.openEditUserDialog,
    };
  }, [usersUIContext]);

  return (
    <Card>
      <CardHeader title={t('users_list')}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={usersUIProps.newUserButtonClick}
          >
            {t('new_user')}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <UsersFilter /> */}
        {usersUIProps.ids.length > 0 && <UsersGrouping />}
        <UsersTable />
      </CardBody>
    </Card>
  );
}
