import axios from "axios";
axios.defaults.headers.common['authorization'] = localStorage.getItem('authToken');

export const EMAILS_URL = `${process.env.REACT_APP_API_URL}emails`;

// CREATE =>  POST: add a new email to the server
export function createEmail(email) {
	return axios.post(EMAILS_URL, { email });
}

// READ
export function getAllEmails() {
	return axios.get(EMAILS_URL);
}

export function getEmailById(emailId) {
	return axios.get(`${EMAILS_URL}/${emailId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findEmails(queryParams) {
	return axios.get(`${EMAILS_URL}?` + queryParams );
}

// UPDATE => PUT: update the email on the server
export function updateEmail(email) {
	return axios.patch(`${EMAILS_URL}/${email._id}`, { email });
}

// UPDATE Status
export function updateStatusForEmails(ids, status) {
	return axios.post(`${EMAILS_URL}/updateStatusForEmails`, {
    	ids,
    	status
	});
}

// DELETE => delete the email from the server
export function deleteEmail(emailId) {
	return axios.delete(`${EMAILS_URL}/${emailId}`);
}

// DELETE Emails by ids
export function deleteEmails(ids) {
	return axios.post(`${EMAILS_URL}/delete`, { ids });
}
