import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./EmailsUIHelpers";

const EmailsUIContext = createContext();

export function useEmailsUIContext() {
	return useContext(EmailsUIContext);
}

export const EmailsUIConsumer = EmailsUIContext.Consumer;

export function EmailsUIProvider({emailsUIEvents, children}) {
	const [queryParams, setQueryParamsBase] = useState(initialFilter);
	const [ids, setIds] = useState([]);
	const setQueryParams = useCallback(nextQueryParams => {
		setQueryParamsBase(prevQueryParams => {
			if (isFunction(nextQueryParams)) {
				nextQueryParams = nextQueryParams(prevQueryParams);
			}

			if (isEqual(prevQueryParams, nextQueryParams)) {
				return prevQueryParams;
      		}

      		return nextQueryParams;
    	});
	}, []);

	const initEmail = {
		_id: undefined,
		name: "",
		subject: "",
		bcc: "",
		content: "",
		mailtags: "",
		is_active: 1,
		is_fixed: 0,
	};

	const value = {
		queryParams,
		setQueryParamsBase,
		ids,
		setIds,
		setQueryParams,
		initEmail,
		newEmailButtonClick: emailsUIEvents.newEmailButtonClick,
		openEditEmailDialog: emailsUIEvents.openEditEmailDialog,
		openDeleteEmailDialog: emailsUIEvents.openDeleteEmailDialog,
		openDeleteEmailsDialog: emailsUIEvents.openDeleteEmailsDialog,
		openFetchEmailsDialog: emailsUIEvents.openFetchEmailsDialog,
		openUpdateEmailsStatusDialog: emailsUIEvents.openUpdateEmailsStatusDialog
	};

	return <EmailsUIContext.Provider value={value}>{children}</EmailsUIContext.Provider>;
}