// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import { t } from "i18next";

import { Input } from "../../../../../_metronic/_partials/controls";
  // Validation schema
  const UserEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, `${t('minimum_3_symbols')}`)
      .max(50, `${t('maximum_50_symbols')}`)
      .required(`${t('firstname_is_required')}`),
    email: Yup.string()
      .email(`${t('invalid_email')}`)
      .required(`${t('email_is_required')}`),
    password: Yup.mixed()
      .nullable(true)
      .required(`${t('password_is_required')}`),
  });

  const getUserPic = (pic) => {
    if (pic === '') {
      return "none";
    }
    if(pic.avatar === '' || pic.hasOwnProperty('avatar') === false){
      return "none"
    }
    let path = `${process.env.REACT_APP_API_URL}${pic.avatar.replace(/\\/g, "/")}`;
    //let path = ``;
    return `url(${path})`;
  };

  const removePic = () => {
    // setPic("");
  };
  export function UserEditForm({
    saveUser,
    user,
    actionsLoading,
    handleFileUpload,
    backToUsersList
  }) {
    return (
      <>
        <Formik
          enableReinitialize={true}
          initialValues={user}
          validationSchema={UserEditSchema}
          onSubmit={(values) => {
            saveUser(values);
          }}
        >
          {({values, handleSubmit }) => (
            <>
              <Modal.Body className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right">
                  <div className="form-group row">
                    <div className="col-lg-4">
                      <label className="col-xl-4 col-lg-4 col-form-label">{t('avatar')}</label>
                      <div className="col-lg-9 col-xl-6" style={{ height: '54px' }}>
                        <div
                          className="image-input image-input-outline"
                          id="kt_profile_avatar"
                          style={{
                            backgroundImage: `url(${toAbsoluteUrl(
                              "/media/users/blank.png"
                            )}`,
                          }}>
                          <div
                            className="image-input-wrapper"
                            style={{ backgroundImage: `${getUserPic(values)}` }}
                          />
                          <label
                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="change"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Change avatar">
                            <i className="fa fa-pen icon-sm text-muted"></i>

                            <input
                              type="file"
                              name="avatar"
                              accept=".png, .jpg, .jpeg"
                              onChange={handleFileUpload}
                            />
                            <input type="hidden" name="profile_pic_remove" />
                          </label>
                          <span
                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="cancel"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Cancel avatar">
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                          </span>
                          <span
                            onClick={removePic}
                            className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                            data-action="remove"
                            data-toggle="tooltip"
                            title=""
                            data-original-title="Remove avatar">
                            <i className="ki ki-bold-close icon-xs text-muted"></i>
                          </span>
                        </div>
                        <span className="form-text text-muted">
                          {t('allowed_file_types')}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <Field
                        name="name"
                        component={Input}
                        placeholder={t('name')}
                        label="Name"
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Field
                        placeholder={t('phone_number')}
                        name="phone_no"
                        component={Input}
                        label={t('phone_number')}
                        withFeedbackLabel={false}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-4">

                    </div>
                    <div className="col-lg-4">
                      <Field
                        type="email"
                        name="email"
                        component={Input}
                        placeholder={t('email')}
                        label={t('email')}
                        withFeedbackLabel={false}
                      />
                    </div>
                    <div className="col-lg-4">
                      <Field
                        placeholder={t('password')}
                        type="password"
                        name="password"
                        component={Input}
                        label={t('password')}
                        withFeedbackLabel={false}
                      />
                    </div>
                  </div>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={backToUsersList}
                  className="btn btn-light btn-elevate"
                >
                  {t('cancel')}
                </button>
                <> </>
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  {t('Save')}
                </button>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </>
    );
  }
