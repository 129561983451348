import axios from "axios";
axios.defaults.headers.common['authorization'] = localStorage.getItem('authToken');

export const ROLES_URL = `${process.env.REACT_APP_API_URL}roles`;

// CREATE =>  POST: add a new role to the server
export function createRole(role) {
	return axios.post(ROLES_URL, { role });
}

// READ
export function getAllRoles() {
	return axios.get(ROLES_URL);
}

export function getRoleById(roleId) {
	return axios.get(`${ROLES_URL}/${roleId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRoles(queryParams) {
	return axios.get(`${ROLES_URL}?` + queryParams);
}

// UPDATE => PUT: update the role on the server
export function updateRole(role) {
	return axios.patch(`${ROLES_URL}/${role._id}`, { role });
}

// UPDATE Status
export function updateStatusForRoles(ids, status) {
	return axios.post(`${ROLES_URL}/updateStatusForRoles`, {
    	ids,
    	status
  	});
}

// DELETE => delete the role from the server
export function deleteRole(roleId) {
	return axios.delete(`${ROLES_URL}/${roleId}`);
}

// DELETE Roles by ids
export function deleteRoles(ids) {
	return axios.post(`${ROLES_URL}/delete`, { ids });
}
