import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useRolesUIContext } from "../RolesUIContext";
import { t } from "i18next";

const prepareFilter = (queryParams, values) => {
	const { name, is_active } = values;
	const newQueryParams = { ...queryParams };
	const filter = {};
	// Filter by status
	filter.is_active = is_active !== "" ? is_active : "";
	// Filter by name
	filter.name = name;
	newQueryParams.filter = filter;
	return newQueryParams;
};

export function RolesFilter() {
	// Roles UI Context
	const rolesUIContext = useRolesUIContext();
	const rolesUIProps = useMemo(() => {
		return {
			queryParams: rolesUIContext.queryParams,
			setQueryParams: rolesUIContext.setQueryParams,
		};
	},[rolesUIContext]);

	// queryParams, setQueryParams,
	const applyFilter = (values) => {
		const newQueryParams = prepareFilter(rolesUIProps.queryParams, values);
		if (!isEqual(newQueryParams, rolesUIProps.queryParams)) {
			newQueryParams.pageNumber = 1;
			// update list by queryParams
			rolesUIProps.setQueryParams(newQueryParams);
		}
	};

	return (
		<>
			<Formik
				initialValues={{
					name: "", 
					is_active: "",
				}}
				onSubmit={(values) => {
					applyFilter(values);
				}}
      		>
			{({
				values,
				handleSubmit,
				handleBlur,
				setFieldValue,
			}) => (
				<form onSubmit={handleSubmit} className="form form-label-right">
					<div className="form-group row">
						<div className="col-lg-1"></div>
						<div className="col-lg-4">
							<input
								type="text"
								className="form-control"
								name="name"
								placeholder={t('name')}
                  				onBlur={handleBlur}
								onChange={(e) => {
                    				setFieldValue("name", e.target.value);
                    				handleSubmit();
                  				}}
                  				value={values.name}
                			/>
              			</div>
						<div className="col-lg-2">
							<select
                  				className="form-control"
                  				placeholder={t('filter_by_status')}
                  				name="is_active"
                  				onBlur={handleBlur}
                  				onChange={(e) => {
                    				setFieldValue("is_active", e.target.value);
                    				handleSubmit();
                  				}}
                  				value={values.is_active}
                			>
                  				<option value="">{t('all')}</option>
								<option value={true}>{t('active')}</option>
                  				<option value={false}>{t('in_active')}</option>
                			</select>
                			<small className="form-text text-muted">
                  				<b>{t('filter_by_status')}</b>
                			</small>
              			</div>
            		</div>
				</form>
			)}
			</Formik>
		</>
	);
}
