import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
// import { UsersFilter } from "./users-filter/UsersFilter";
import { RolesTable } from "./roles-table/RolesTable";
import { RolesGrouping } from "./roles-grouping/RolesGrouping";
import { useRolesUIContext } from "./RolesUIContext";
import { useTranslation } from "react-i18next";
//import { t } from "i18next";

export function RolesCard() {
  const { t } = useTranslation();
  const rolesUIContext = useRolesUIContext();
  const rolesUIProps = useMemo(() => {
    return {
      ids: rolesUIContext.ids,
      newRoleButtonClick: rolesUIContext.newRoleButtonClick,
    };
  }, [rolesUIContext]);

  return (
    <Card>
      <CardHeader title={t('roles_list')}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={rolesUIProps.newRoleButtonClick}
          >
            {t('new_role')}
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <RolesTable /> */}
        {rolesUIProps.ids.length > 0 && <RolesGrouping />}
        <RolesTable />
      </CardBody>
    </Card>
  );
}
