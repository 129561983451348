import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import { Input } from "../../../_metronic/_partials/controls";
//import * as requestFromServer from "../../../redux/Auth/authCrud";
import * as actions from "../../../redux/users/usersActions";
import { t } from "i18next";

function PersonaInformation(props) {
	// Fields
	const [loading, setLoading] = useState(false);
	const [selectedAvatarFile, setSelectedAvatarFile] = useState(null);
	const [setPic] = useState("");
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user, shallowEqual);
	useEffect(() => {
		if (user.pic) {
			setPic(user.pic);
		}
	}, [user, setPic]);

	const saveUser = async (data) => {
		setLoading(true);
		await dispatch(actions.updateProfile(data));
		setLoading(false);
	};

	// UI Helpers
	const initialValues = {
		avatar: (user.data.avatar) ? user.data.avatar : "",
		name: user.data.name,
		mobile_no: (user.data.mobile_no) ? user.data.mobile_no : "",
		email: user.data.email,
	};

	const handleAvatarFileSelect = (event) => {
		setSelectedAvatarFile(event.target.files[0])
	}

	const userInfoSchema = Yup.object().shape({
		pic: Yup.string(),
		name: Yup.string().required(`${t('firstname_is_required')}`),
		mobile_no: Yup.string().required(`${t('mobile_number_is_required')}`),
		email: Yup.string()
			.email(`${t('wrong_email_format')}`)
			.required(`${t('email_is_required')}`),
	});

	const getUserPic = (pic) => {
		if (selectedAvatarFile) {
			return `url(${URL.createObjectURL(selectedAvatarFile)})`;
		  }
		if (pic === '') {
		  return "none";
		}
		if(pic.avatar === '' || pic.hasOwnProperty('avatar') === false){
		  return "none"
		}
		let path = `${process.env.REACT_APP_API_URL}${pic.avatar.replace(/\\/g, "/")}`;
		return `url(${path})`;
	  };

	const removePic = () => {
    	setPic("");
  	};

	return (
		<>
		  <Formik
			enableReinitialize={true}
			validationSchema={userInfoSchema}
			initialValues={initialValues}
			onSubmit={(values) => {
				console.log(JSON.stringify(values));
				let data = new FormData();
				Object.keys(values).forEach(key => {
				  if (key !== 'avatar' && key !== 'id') {
					if (typeof values[key] !== 'undefined') {
						data.append(key, values[key]);
					}
				  }
				});
				if(selectedAvatarFile){
					data.append("avatar", selectedAvatarFile);
				}
				//console.log(data.get('first_name'))
				saveUser(data);
			  }}
		  >
			{({ handleSubmit, values, touched, errors }) => (
			  <>
			  	<div className="card card-custom card-stretch">
					{/* begin::Header */}
					<div className="card-header py-3">
						<div className="card-title align-items-start flex-column">
							<h3 className="card-label font-weight-bolder text-dark">
                    			{t('personal_information')}
                    		</h3>
                    		<span className="text-muted font-weight-bold font-size-sm mt-1">
                    			{t('update_personal_information')}
                    		</span>
						</div>
						<div className="card-toolbar">
							<button
								type="submit"
								onClick={() => handleSubmit()}
								className="btn btn-success mr-2"						
							>
								{t('save_changes')}
							</button>
							<Link
								to="/user-profile/profile-overview"
								className="btn btn-secondary"
							>
								{t('cancel')}
							</Link>
						</div>
					</div>
					{/* end::Header */}
					{/* begin::Form */}
					<div className="form">
						<div className="card-body">
							{loading && (
								<div className="overlay-layer bg-transparent">
									<div className="spinner spinner-lg spinner-success" />
								</div>
							)}
							<div className="row">
								<label className="col-xl-3"></label>
								<div className="col-lg-9 col-xl-6">
									<h5 className="font-weight-bold mb-6">{t('customer_info')}</h5>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">{t('avatar')}</label>
								<div className="col-lg-9 col-xl-6">
									<div
										className="image-input image-input-outline"
										id="kt_profile_avatar"
										style={{
											backgroundImage: `url(${toAbsoluteUrl(
												"/media/users/blank.png"
											)}`,
										}}>
										<div
											className="image-input-wrapper"
											style={{ backgroundImage: `${getUserPic(values)}` }}
										/>
										<label
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="change"
											data-toggle="tooltip"
											title=""
											data-original-title={t('change_avatar')}>
											<i className="fa fa-pen icon-sm text-muted"></i>
											<input
												type="file"
												name="avatar"
												onChange={handleAvatarFileSelect}
												accept=".png, .jpg, .jpeg"
											/>
											<input type="hidden" name="profile_pic_remove" />
										</label>
										<span
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="cancel"
											data-toggle="tooltip"
											title=""
											data-original-title={t('cancel_avatar')}>
											<i className="ki ki-bold-close icon-xs text-muted"></i>
										</span>
										<span
											onClick={removePic}
											className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
											data-action="remove"
											data-toggle="tooltip"
											title=""
											data-original-title={t('remove_avatar')}>
											<i className="ki ki-bold-close icon-xs text-muted"></i>
										</span>
									</div>
									<span className="form-text text-muted">
										{t('allowed_file_types')}.
									</span>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									{t('name')}
								</label>
								<div className="col-lg-9 col-xl-6">
									<Field
										placeholder={t('name')}
										className={`form-control form-control-lg form-control-solid`}
										name="name"
										component={Input}
										withFeedbackLabel={false}
									/>
									{touched.name && errors.name ? (
										<div className="invalid-feedback">
											{errors.name}
										</div>
									) : null}
								</div>
							</div>

							{/* <div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									{t('last_name')}
								</label>
								<div className="col-lg-9 col-xl-6">
									<Field
										placeholder={t('last_name')}
										className={`form-control form-control-lg form-control-solid`}
										name="last_name"
										component={Input}
										withFeedbackLabel={false}
									/>
									{touched.last_name && errors.last_name ? (
										<div className="invalid-feedback">
											{errors.last_name}
										</div>
									) : null}
								</div>
							</div> */}

							<div className="row">
								<label className="col-xl-3"></label>
								<div className="col-lg-9 col-xl-6">
									<h5 className="font-weight-bold mt-10 mb-6">{t('contanct_info')}</h5>
								</div>
							</div>

							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									{t('contact_phone')}
								</label>
								<div className="col-lg-9 col-xl-6">
									<div className="input-group input-group-lg input-group-solid">
										<div className="input-group-prepend">
											<span className="input-group-text">
											<i className="fa fa-phone"></i>
											</span>
										</div>
										<Field
											placeholder="+1(123)112-11-11"
											className={`form-control form-control-lg form-control-solid`}
											name="mobile_no"
											component={Input}
											withFeedbackLabel={false}
										/>
									</div>
									<span className="form-text text-muted">
										{t('phone_privacy_desc')}.
									</span>
								</div>
							</div>
							
							<div className="form-group row">
								<label className="col-xl-3 col-lg-3 col-form-label">
									{t('email_address')}
								</label>
								<div className="col-lg-9 col-xl-6">
									<div className="input-group input-group-lg input-group-solid">
										<div className="input-group-prepend">
											<span className="input-group-text">
												<i className="fa fa-at"></i>
											</span>
										</div>
										<Field
											type="email"
											placeholder={t('email')}
											className={`form-control form-control-lg form-control-solid`}
											name="email"
											component={Input}
											withFeedbackLabel={false}
											disabled={true}
										/>
									</div>
									{touched.email && errors.email ? (
										<div className="invalid-feedback">
											{errors.email}
										</div>
									) : null}
								</div>
							</div>
						</div>
					</div>
					{/* end::Form */}
				</div>
			  </>
			)}
		  </Formik>
		</>
	);
}

export default connect(null, auth.actions)(PersonaInformation);
