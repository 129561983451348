import * as requestFromServer from "./settingsCrud";
import { settingsSlice, callTypes } from "./settingsSlice";

const { actions } = settingsSlice;

export const fetchSetting = id => dispatch => {
    dispatch(actions.startCall({ callType: callTypes.action }));

    if (!id) {
        return requestFromServer
            .getSetting()
            .then(response => {
                const setting = response.data;
                dispatch(actions.settingFetched({ settingForEdit: setting }));
            })
            .catch(error => {
                error.clientMessage = "Can't find setting";
                dispatch(actions.catchError({ error, callType: callTypes.action }));
            });
    }

    return requestFromServer
        .getSettingById(id)
        .then(response => {
            const setting = response.data;
            dispatch(actions.settingFetched({ settingForEdit: setting }));
        })
        .catch(error => {
            error.clientMessage = "Can't find setting";
            dispatch(actions.catchError({ error, callType: callTypes.action }));
        });
};

export const createSetting = settingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSetting(settingForCreation)
    .then(response => {
      debugger
      const { setting } = response.data;
      dispatch(actions.settingCreated({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't create setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSetting = setting => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSetting(setting)
    .then(() => {
      dispatch(actions.settingUpdated({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const createSettingLogo = settingForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSettingLogo(settingForCreation)
    .then(response => {
      const { setting } = response.data;
      dispatch(actions.settingCreatedLogo({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't create setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSettingLogo = setting => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSetting(setting)
    .then(() => {
      dispatch(actions.settingUpdatedLogo({ setting }));
    })
    .catch(error => {
      error.clientMessage = "Can't update setting";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOrUpdateSetting = setting => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
    .createOrUpdateSetting(setting)
    .then(response => {
		const setting  = response.data;
		dispatch(actions.settingFetched({ settingForEdit: setting }));
    })
    .catch(error => {
		error.clientMessage = "Can't create setting";
		dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}

export const sendTestMail = setting => dispatch => {
	dispatch(actions.startCall({ callType: callTypes.action }));
	return requestFromServer
    .sendTestMail(setting)
    .then(response => {
		const setting  = response.data;
		dispatch(actions.settingFetched({ settingForEdit: setting }));
    })
    .catch(error => {
		error.clientMessage = "Can't send mail";
		dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
}


