import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, connect, useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import * as auth from "../Auth";
import * as actions from "../../../redux/settings/settingsActions";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

function AdminMedia(props) {
  // Settings Redux state
  const { t } = useTranslation();
  const [selectedLogoFile, setSelectedLogoFile] = useState(null);
  const [selectedBackgroundFile, setSelectedBackgroundFile] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    // server call for getting Setting by id
    dispatch(actions.fetchSetting());
  }, [dispatch]);
 
  const { settingForEdit,actionsLoading } = useSelector(
    (state) => ({
      actionsLoading: state.settings.actionsLoading,
      settingForEdit: state.settings.settingForEdit
    })
  );

  // server request for saving setting
  const saveEmailSetting = (setting) => {
    dispatch(actions.createSettingLogo(setting));
  };

  // UI Helpers
  let initialValues = {
    logo: (settingForEdit) ? settingForEdit.logo : "",
    id: (settingForEdit) ? settingForEdit._id : "",
    background: (settingForEdit) ? settingForEdit.background : "",
  };

  const handleLogoFileSelect = (event) => {
    setSelectedLogoFile(event.target.files[0])
  }

  const handleBackgroundFileSelect = (event) => {
    setSelectedBackgroundFile(event.target.files[0])
  }

  const getLogo = (pic) => {
    if (selectedLogoFile) {
      return `url(${URL.createObjectURL(selectedLogoFile)})`;
    }
    if (pic === '') {
      return "none";
    }
    if (pic.logo === '' || pic.hasOwnProperty('logo') === false) {
      return "none"
    }
    let path = `${process.env.REACT_APP_API_URL}${pic.logo.replace(/\\/g, "/")}`;
    return `url(${path})`;
  };
  const getBackground = (pic) => {
    if (selectedBackgroundFile) {
      return `url(${URL.createObjectURL(selectedBackgroundFile)})`;
    }
    if (pic === '') {
      return "none";
    }
    if (pic.background === '' || pic.hasOwnProperty('background') === false) {
      return "none"
    }
    let path = `${process.env.REACT_APP_API_URL}${pic.background.replace(/\\/g, "/")}`;
    return `url(${path})`;
  };
  const removeLogo = (resetForm) => {
    initialValues.logo = '';
    setSelectedLogoFile('');
    resetForm({ values: initialValues });
  };
  const removeBackground = (resetForm) => {
    initialValues.background = '';
    setSelectedBackgroundFile('');
    resetForm({ values: initialValues });
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          const formData = new FormData();
          values = (!settingForEdit) ? values : settingForEdit
          Object.keys(values).forEach(key => {
            if (key !== 'logo' && key !== 'background' && key !== 'id') {
              if (typeof values[key] !== 'undefined') {
                formData.append(key, values[key]);
              }
            }
          });
          formData.append("logo", selectedLogoFile);
          formData.append("background", selectedBackgroundFile);
          console.log('=============admin media');
          saveEmailSetting(formData);
        }}
      >
        {({ handleSubmit, values, resetForm }) => (
          <>
            <div className="card card-custom">
              {/* begin::Header */}
              {/* begin::Header */}
              <div className="card-header py-3">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label font-weight-bolder text-dark">
                    {t('admin_media')}
                  </h3>
                </div>
                <div className="card-toolbar">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-success mr-2"
                  >
                    {t('save_changes')}
                  </button>
                  <Link
                    to="/general-setting/admin-media"
                    className="btn btn-secondary"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
              {/* end::Header */}
              {/* begin::Form */}
              <div className="form">
                {/* begin::Body */}
                <div className="card-body">
                  {actionsLoading && (
                    <div className="overlay-layer bg-transparent">
                      <div className="spinner spinner-lg spinner-success" />
                    </div>
                  )}
                  <div className="row">
                    <label className="col-xl-3"></label>
                    <div className="col-lg-9 col-xl-6">
                      {/* <h5 className="font-weight-bold mb-6">Customer Info</h5> */}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">{t('company_logo')}</label>
                    <div className="col-lg-9 col-xl-6">
                      <div
                        className="image-input image-input-outline"
                        id="kt_profile_avatar"
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(
                            "/media/users/blank.png"
                          )}`,
                        }}
                      >
                        <div
                          className="image-input-wrapper"
                          style={{ backgroundImage: `${getLogo(values)}` }}
                        />
                        <label
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <i className="fa fa-pen icon-sm text-muted"></i>
                          <input
                            type="file"
                            name="logo"
                            onChange={handleLogoFileSelect}
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="profile_pic_remove" />
                        </label>
                        <span
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cancel avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span
                          onClick={removeLogo.bind(null, resetForm)}
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Remove avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                      <span className="form-text text-muted">
                        {t('background')}
                      </span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-xl-3 col-lg-3 col-form-label">{t('background')}</label>
                    <div className="col-lg-9 col-xl-6">
                      <div
                        className="image-input image-input-outline"
                        id="kt_profile_avatar"
                        style={{
                          backgroundImage: `url(${toAbsoluteUrl(
                            "/media/users/blank.png"
                          )}`,
                        }}
                      >
                        <div
                          className="image-input-wrapper"
                          style={{ backgroundImage: `${getBackground(values)}` }}
                        />
                        <label
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="change"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Change avatar"
                        >
                          <i className="fa fa-pen icon-sm text-muted"></i>
                          <input
                            type="file"
                            name="background"
                            onChange={handleBackgroundFileSelect}
                            accept=".png, .jpg, .jpeg"
                          />
                          <input type="hidden" name="profile_pic_remove" />
                        </label>
                        <span
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="cancel"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cancel avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                        <span
                          onClick={removeBackground.bind(null, resetForm)}
                          className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                          data-action="remove"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Remove avatar"
                        >
                          <i className="ki ki-bold-close icon-xs text-muted"></i>
                        </span>
                      </div>
                      <span className="form-text text-muted">
                        Allowed file types: png, jpg, jpeg.
                      </span>
                    </div>
                  </div>
                </div>
                {/* end::Body */}
              </div>
              {/* end::Form */}
            </div>
          </>
        )}
      </Formik>
    </>
  );
}

export default connect(null, auth.actions)(AdminMedia);
