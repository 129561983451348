import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}auth/signin`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}auth/me`;
export const UPDATE_PROFILE_URL = `${process.env.REACT_APP_API_URL}auth/update`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}auth/reset`;
axios.defaults.headers.put['Content-Type'] = "multipart/form-data";
// Authorization head should be fulfilled in interceptor.
// const your_token= utils.getStorage('authToken')
const your_token = localStorage.getItem('authToken');

export async function login(email, password) {
	return await axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
	return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
	return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
	return axios.get(ME_URL,{
		headers: {
		'authorization': your_token,
		'Accept' : 'application/json',
		'Content-Type': 'application/json'
		}
	});

	// return {
	//   data: {
	//     id: 1,
	//     username: "admin",
	//     password: "demo",
	//     email: "admin@demo.com",
	//     authToken: "auth-token-8f3ae836da744329a6f93bf20594b5cc",
	//     refreshToken: "auth-token-f8c137a2c98743f48b643e71161d90aa",
	//     roles: [1], // Administrator
	//     pic: toAbsoluteUrl("/media/users/300_21.jpg"),
	//     fullname: "Sean S",
	//     firstname: "Sean",
	//     lastname: "Stark",
	//     occupation: "CEO",
	//     companyName: "Keenthemes",
	//     phone: "456669067890",
	//     language: "en",
	//     timeZone: "International Date Line West",
	//     website: "https://keenthemes.com",
	//     emailSettings: {
	//       emailNotification: true,
	//       sendCopyToPersonalEmail: false,
	//       activityRelatesEmail: {
	//         youHaveNewNotifications: false,
	//         youAreSentADirectMessage: false,
	//         someoneAddsYouAsAsAConnection: true,
	//         uponNewOrder: false,
	//         newMembershipApproval: false,
	//         memberRegistration: true,
	//       },
	//       updatesFromKeenthemes: {
	//         newsAboutKeenthemesProductsAndFeatureUpdates: false,
	//         tipsOnGettingMoreOutOfKeen: false,
	//         thingsYouMissedSindeYouLastLoggedIntoKeen: true,
	//         newsAboutMetronicOnPartnerProductsAndOtherServices: true,
	//         tipsOnMetronicBusinessProducts: true,
	//       },
	//     },
	//     communication: {
	//       email: true,
	//       sms: true,
	//       phone: false,
	//     },
	//     address: {
	//       addressLine: "L-12-20 Vertex, Cybersquare",
	//       city: "San Francisco",
	//       state: "California",
	//       postCode: "45000",
	//     },
	//     socialNetworks: {
	//       linkedIn: "https://linkedin.com/admin",
	//       facebook: "https://facebook.com/admin",
	//       twitter: "https://twitter.com/admin",
	//       instagram: "https://instagram.com/admin",
	//     },
	//   }
	// };
}

export function updateProfile(user) {
	return axios.post(UPDATE_PROFILE_URL,{
		headers: {
			'authorization': your_token,
			'Content-Type': 'multipart/form-data'
		},
    	user
  	});
}

export function resetPassword(passwordData) {
	return axios.post(RESET_PASSWORD_URL,{
		headers: {
			'authorization': your_token,
			'Accept' : 'application/json',
			'Content-Type': 'application/json'
		},
    	passwordData
  	});
}