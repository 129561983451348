/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import * as auth from "../Auth";
import { Input } from "../../../_metronic/_partials/controls";
import * as requestFromServer from "../../../redux/Auth/authCrud";
import { t } from "i18next";

function ChangePassword(props) {
	// Fields
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user, shallowEqual);
	useEffect(() => {}, [user]);
	// Methods
	const resetPassword = (values, setStatus, setSubmitting) => {
		setLoading(true);

		requestFromServer.resetPassword(values)
			.then(response => {
				dispatch(props.setUser(response.data));
				setLoading(false);
			})
			.catch(error => {
				error.clientMessage = "Can't update profile";
				setSubmitting(false);
				setStatus(error);
				setLoading(false);
			});

		// user for update preparation
		/*dispatch(props.setUser(updatedUser));
		setTimeout(() => {
			setloading(false);
			setSubmitting(false);
			// Do request to your server for user update, we just imitate user update there, For example:
			// update(updatedUser)
			//  .then(()) => {
			//    setloading(false);
			//  })
			//  .catch((error) => {
			//    setloading(false);
			//    setSubmitting(false);
			//    setStatus(error);
			// });
		}, 1000);*/
  	};

	// UI Helpers
	const initialValues = {
		currentPassword: "",
		password: "",
		cPassword: "",
	};

	const passwordSchema = Yup.object().shape({
		currentPassword: Yup.string().required(`${t('current_password_is_required')}`),
		password: Yup.string().required(`${t('new_password_is_required')}`),
		cPassword: Yup.string()
			.required(`${t('password_confirmation_is_required')}`)
			.when("password", {
				is: (val) => (val && val.length > 0 ? true : false),
				then: Yup.string().oneOf(
					[Yup.ref("password")],
					`${t('password_and_confirm_password_didnt_match')}`
				),
			}),
	});

	return (
    	<>
			<Formik
				enableReinitialize={true}
				validationSchema={passwordSchema}
				initialValues={initialValues}
				onSubmit={(values, {setStatus, setSubmitting}) => {
					resetPassword(values, setStatus, setSubmitting);
				}}
			>
				{({ handleSubmit, touched, errors }) => (
				<>
					<div className="card card-custom card-stretch">
						{/* begin::Header */}
						<div className="card-header py-3">
							<div className="card-title align-items-start flex-column">
								<h3 className="card-label font-weight-bolder text-dark">
									{t('change_password')}
								</h3>
								<span className="text-muted font-weight-bold font-size-sm mt-1">
									{t('change_your_account_password')}
								</span>
							</div>
							<div className="card-toolbar">
								<button
									type="submit"
									onClick={() => handleSubmit()}
									className="btn btn-success mr-2"						
								>
									{t('save_changes')}
								</button>
								<Link
									to="/user-profile/profile-overview"
									className="btn btn-secondary"
								>
									{t('cancel')}
								</Link>
							</div>
						</div>
						{/* end::Header */}
						{/* begin::Form */}
						<div className="form">
							<div className="card-body">
								{loading && (
									<div className="overlay-layer bg-transparent">
										<div className="spinner spinner-lg spinner-success" />
									</div>
								)}
								<div className="form-group row">
            						<label className="col-xl-3 col-lg-3 col-form-label text-alert">
									{t('current_password')}
            						</label>
            						<div className="col-lg-9 col-xl-6">
              							<Field
                							type="password"
                							placeholder={t('current_password')}
                							className={`form-control form-control-lg form-control-solid mb-2`}
                							name="currentPassword"
											component={Input}
											withFeedbackLabel={false}
              							/>
              							{touched.currentPassword && errors.currentPassword ? (
                							<div className="invalid-feedback">
                  								{errors.currentPassword}
                							</div>
              							) : null}
              							<a href="#" className="text-sm font-weight-bold">
                							{t('forgot_password')} ?
              							</a>
           							</div>
          						</div>

								<div className="form-group row">
            						<label className="col-xl-3 col-lg-3 col-form-label text-alert">
									{t('new_password')}
           							</label>
            						<div className="col-lg-9 col-xl-6">
              							<Field
                							type="password"
                							placeholder={t('new_password')}
                							className={`form-control form-control-lg form-control-solid`}
                							name="password"
											component={Input}
											withFeedbackLabel={false}
              							/>
              							{touched.password && errors.password ? (
                							<div className="invalid-feedback">{errors.password}</div>
              							) : null}
            						</div>
          						</div>
								
								<div className="form-group row">
            						<label className="col-xl-3 col-lg-3 col-form-label text-alert">
									{t('verify_password')}
            						</label>
            						<div className="col-lg-9 col-xl-6">
										<Field
											type="password"
											placeholder={t('verify_password')}
											className={`form-control form-control-lg form-control-solid`}
											name="cPassword"
											component={Input}
											withFeedbackLabel={false}
										/>
										{touched.cPassword && errors.cPassword ? (
											<div className="invalid-feedback">
												{errors.cPassword}
											</div>
										) : null}
            						</div>
        						</div>

							</div>
						</div>
						{/* end::Form */}
					</div>
				</>
				)}
			</Formik>
		</>
	);
}

export default connect(null, auth.actions)(ChangePassword);
