import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/emails/emailsActions";
import { useEmailsUIContext } from "../EmailsUIContext";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";
import { t } from "i18next";

export function EmailsDeleteDialog({ show, onHide }) {
  // Emails UI Context
  const emailsUIContext = useEmailsUIContext();
  const emailsUIProps = useMemo(() => {
    return {
      ids: emailsUIContext.ids,
      setIds: emailsUIContext.setIds,
      queryParams: emailsUIContext.queryParams,
    };
  }, [emailsUIContext]);

  // Emails Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.emails.actionsLoading }),
    shallowEqual
  );

  // if emails weren't selected we should close modal
  useEffect(() => {
    if (!emailsUIProps.ids || emailsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailsUIProps.ids]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteEmails = () => {
    // server request for deleting email by selected ids
    dispatch(actions.deleteEmails(emailsUIProps.ids)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchEmails(emailsUIProps.queryParams)).then(
        () => {
          // clear selections list
          emailsUIProps.setIds([]);
          // closing delete modal
          onHide();
        }
      );
    });
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          {t('emails_delete')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>{t('are_you_sure_to_permanently_delete_selected_emails')}</span>
        )}
        {isLoading && <span>{t('email_are_deleting')}</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            {t('cancel')}
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteEmails}
            className="btn btn-primary btn-elevate"
          >
            {t('delete')}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
