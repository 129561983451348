import React, { useMemo } from "react";
import { useRolesUIContext } from "../RolesUIContext";

export function RolesGrouping() {
  // Users UI Context
  const rolesUIContext = useRolesUIContext();
  const rolesUIProps = useMemo(() => {
    return {
      ids: rolesUIContext.ids,
      setIds: rolesUIContext.setIds,
      openDeleteRolesDialog: rolesUIContext.openDeleteRolesDialog,
      openFetchRolesDialog: rolesUIContext.openFetchRolesDialog,
      openUpdateRolesStatusDialog:
        rolesUIContext.openUpdateRolesStatusDialog,
    };
  }, [rolesUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  Selected records count: <b>{rolesUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={rolesUIProps.openDeleteRolesDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
