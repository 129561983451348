import { t } from "i18next";
import React, { useMemo } from "react";
import { useUsersUIContext } from "../UsersUIContext";

export function UsersGrouping() {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      setIds: usersUIContext.setIds,
      openDeleteUsersDialog: usersUIContext.openDeleteUsersDialog,
      openFetchUsersDialog: usersUIContext.openFetchUsersDialog,
      openUpdateUsersStatusDialog:
        usersUIContext.openUpdateUsersStatusDialog,
    };
  }, [usersUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20 margin-bottom-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger">
                <span>
                  {t('selected_record_count')}: <b>{usersUIProps.ids.length}</b>
                </span>
              </label>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={usersUIProps.openDeleteUsersDialog}
              >
                <i className="fa fa-trash"></i> {t('delete_all')}
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
