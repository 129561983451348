// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
	PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../redux/users/usersActions";
import {
	getSelectRow,
	getHandlerTableChange,
	NoRecordsFoundMessage,
	PleaseWaitMessage,
	sortCaret,
	headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../UsersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { useUsersUIContext } from "../UsersUIContext";
import { createQueryString } from '../../../../Helpers';
import { UsersFilter } from "../users-filter/UsersFilter";
import { useTranslation } from "react-i18next";

export function UsersTable() {
	const { t } = useTranslation();
	// Users UI Context
	const usersUIContext = useUsersUIContext();
	const usersUIProps = useMemo(() => {
		return {
			ids: usersUIContext.ids,
			setIds: usersUIContext.setIds,
			queryParams: usersUIContext.queryParams,
			setQueryParams: usersUIContext.setQueryParams,
			openEditUserDialog: usersUIContext.openEditUserDialog,
			openDeleteUserDialog: usersUIContext.openDeleteUserDialog,
		};
	}, [usersUIContext]);

	// Getting curret state of users list from store (Redux)
	const { currentState } = useSelector(
    	(state) => ({ currentState: state.users }),
    	shallowEqual
	);

	const { totalCount, entities, listLoading } = currentState;

	// Users Redux state
	const dispatch = useDispatch();
	useEffect(() => {
    	// clear selections list
    	usersUIProps.setIds([]);
    	// server call by queryParams
    	dispatch(actions.fetchUsers(createQueryString(usersUIProps.queryParams)));
    	// eslint-disable-next-line react-hooks/exhaustive-deps
  	}, [usersUIProps.queryParams, dispatch]);
  	
	// Table columns
	const columns = [
    	{
			dataField: "name",
			text: `${t('name')}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
		},
    	{
			dataField: "email",
			text: `${t('email')}`,
			sort: true,
			sortCaret: sortCaret,
			headerSortingClasses,
    	},
    	{
			dataField: "is_active",
			text: `${t('active')}`,
			sort: true,
			sortCaret: sortCaret,
			formatter: columnFormatters.StatusColumnFormatter,
			headerSortingClasses,
    	},
    	{
			dataField: "action",
			text: `${t('actions')}`,
			formatter: columnFormatters.ActionsColumnFormatter,
			formatExtraData: {
				openEditUserDialog: usersUIProps.openEditUserDialog,
				openDeleteUserDialog: usersUIProps.openDeleteUserDialog,
      		},
			classes: "text-right pr-0",
			headerClasses: "text-right pr-3",
      		style: {
        		minWidth: "100px",
      		},
    	},
	];

	// Table pagination properties
	const paginationOptions = {
		custom: true,
		totalSize: totalCount,
		sizePerPageList: uiHelpers.sizePerPageList,
		sizePerPage: usersUIProps.queryParams.pageSize,
		page: usersUIProps.queryParams.pageNumber,
	};

	return (
    	<>
			<PaginationProvider pagination={paginationFactory(paginationOptions)}>
				{({ paginationProps, paginationTableProps }) => {
					return (
						<Pagination
							isLoading={listLoading}
							paginationProps={paginationProps}
						>
							<UsersFilter></UsersFilter>
							<BootstrapTable
								wrapperClasses="table-responsive"
								bordered={false}
								classes="table table-head-custom table-vertical-center overflow-hidden"
								bootstrap4
								remote
								keyField="_id"
								data={entities === null ? [] : entities}
								columns={columns}
								defaultSorted={uiHelpers.defaultSorted}
								onTableChange={getHandlerTableChange(
									usersUIProps.setQueryParams
								)}
								selectRow={getSelectRow({
									entities,
									ids: usersUIProps.ids,
									setIds: usersUIProps.setIds,
								})}
								{...paginationTableProps}
							>
								<PleaseWaitMessage entities={entities} />
								<NoRecordsFoundMessage entities={entities} />
							</BootstrapTable>
						</Pagination>
					);
				}}
			</PaginationProvider>
    	</>
  	);
}
