/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../../redux/emails/emailsActions";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { EmailEditForm } from "./EmailEditForm";
import { useSubheader } from "../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../_metronic/_partials/controls";


export function EmailEdit({
  history,
  match: {
    params: { id },
  },
}) {

    const initEmail = {
		_id: undefined,
		name: "",
		subject: "",
		bcc: "",
		content: "",
		mailtags: "",
		is_active: 1,
		is_fixed: 0,
	};

  // Subheader
  const suhbeader = useSubheader();

  // Tabs
  const [title, setTitle] = useState("");

    // Emails Redux state
	const dispatch = useDispatch();
	const { actionsLoading, emailForEdit } = useSelector(
		(state) => ({
			actionsLoading: state.emails.actionsLoading,
			emailForEdit: state.emails.emailForEdit,
		}),
    	shallowEqual
  	);

  useEffect(() => {
    dispatch(actions.fetchEmail(id));
  }, [id, dispatch]);

  useEffect(() => {
    let _title = id ? "" : "New Email";
    if (emailForEdit && id) {
      console.log(emailForEdit)
      _title = `Edit Email`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailForEdit, id]);

    // server request for saving email
	const saveEmail = (email) => {
		if (!id) {
			// server request for creating email
			dispatch(actions.createEmail(email)).then(() => backToEmailList());
		} else {
			// server request for updating email
			dispatch(actions.updateEmail(email)).then(() => backToEmailList());
		}
  	};

  const editEmailValue = {
    _id : (emailForEdit) ? emailForEdit._id : undefined,
    name : (emailForEdit) ? emailForEdit.name : "",
    subject : (emailForEdit) ? emailForEdit.subject : "",
    type : (emailForEdit) ? emailForEdit.type : "",
    bcc : (emailForEdit) ? (emailForEdit.bcc) ? emailForEdit.bcc : "" : "",
    content : (emailForEdit) ? emailForEdit.content : "",
    mailtags : (emailForEdit) ? (emailForEdit.mailtags) ? emailForEdit.mailtags : "" : "",
    dynamic_fields: (emailForEdit) ? (emailForEdit.dynamic_fields) ? emailForEdit.dynamic_fields : "" : "",
    is_active : (emailForEdit) ? emailForEdit.is_active : 1,
    is_fixed : (emailForEdit) ? emailForEdit.is_fixed : 0,
    unique_identifier : (emailForEdit) ? emailForEdit.unique_identifier : "",
    updated_at : (emailForEdit) ? emailForEdit.updated_at : "",
};

  const backToEmailList = () => {
    history.push(`/emails`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
      </CardHeader>
      <CardBody>
        <div className="mt-5">
            <EmailEditForm
              actionsLoading={actionsLoading}
              email={editEmailValue || initEmail}
              saveEmail={saveEmail}
              backToEmailList = {backToEmailList}
            />
        </div>
      </CardBody>
    </Card>
  );
}
