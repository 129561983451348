// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../_metronic/_partials/controls";
import { t } from "i18next";

// Validation schema
const RoleEditSchema = Yup.object().shape({
  name: Yup.string()
	.min(3, `${t('maximum_3_symbols')}`)
	.max(50, `${t('maximum_50_symbols')}`)
	.required(`${t('name_is_required')}`),
});

export function RoleEditForm({
  saveRole,
  role,
  actionsLoading,
  backToRoleList,
}) {
  return (
	<>
	  <Formik
		enableReinitialize={true}
		initialValues={role}
		validationSchema={RoleEditSchema}
		onSubmit={(values) => {
		  saveRole(values);
		}}
	  >
		{({ handleSubmit, handleChange }) => (
		  <>
			<Modal.Body className="overlay overlay-block cursor-default">
			  {actionsLoading && (
				<div className="overlay-layer bg-transparent">
				  <div className="spinner spinner-lg spinner-success" />
				</div>
			  )}
			  <Form className="form form-label-right">
				<div className="form-group row">
				<label className="col-xl-3 col-lg-3 col-form-label">{t('name')}</label>
				  	{/* Name */}
				  	<div className="col-lg-9 col-xl-6">
						<Field
						  name="name"
						  component={Input}
						  placeholder={t('name')}
						  withFeedbackLabel={false}
						/>
				  	</div>
				</div>
				<div className="form-group row">
					<label className="col-xl-3 col-lg-3 col-form-label">{t('active')}</label>
				  	{/* Active */}
				  	<div className="col-lg-9 col-xl-6">
					  	<Field 
						  	type="checkbox" 
							name="is_active" 
						/>
				  	</div>
				</div>
				<div className="form-group row">
					<label className="col-xl-3 col-lg-3 col-form-label">Permission</label>
				  	{/* Active */}
				  	<div className="col-lg-9 col-xl-6">
					  	<Field 
						  	type="checkbox" 
							name="permission.dashboard" 
						/> {t('dashboard')}
						<br/>
						<Field 
						  	type="checkbox" 
							name="permission.users" 
						/> {t('users')}
						<br/>
						<Field 
						  	type="checkbox" 
							name="permission.roles" 
						/> {t('roles')}
						<br/>
						<Field 
						  	type="checkbox" 
							name="permission.emails" 
						/> Emails
						<br/>
						<Field 
						  	type="checkbox" 
							name="permission.logs" 
						/> {t('logs')}
						<br/>
						<Field 
						  	type="checkbox" 
							name="permission.general_setting" 
						/> {t('general_settings')}
				  	</div>
				</div>
				
			  </Form>
			</Modal.Body>
			<Modal.Footer>
			  <button
				type="button"
				onClick={backToRoleList}
				className="btn btn-light btn-elevate"
			  >
				{t('cancel')}
			  </button>
			  <> </>
			  <button
				type="submit"
				onClick={() => handleSubmit()}
				className="btn btn-primary btn-elevate"
			  >
				{t('Save')}
			  </button>
			</Modal.Footer>
		  </>
		)}
	  </Formik>
	</>
  );
}
