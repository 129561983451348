import React, { useEffect } from "react";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import * as actions from "../../../redux/logs/logsActions";
import moment from "moment";
import { t } from "i18next";

export default function LogPage() {
  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // server call by queryParams
    dispatch(actions.fetchLogs({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { currentState } = useSelector(
    (state) => ({ currentState: state.logs }),
    shallowEqual
  );  
  const { logs } = currentState;
  return (
    <>
      <div className={`card card-custom`}>
        {/* Header */}
        <div className="card-header align-items-center border-0 mt-4">
          <h3 className="card-title align-items-start flex-column">
            <span className="font-weight-bolder text-dark">{t('logs')}</span>
          </h3>
          <div className="card-toolbar">
          </div>
        </div>
        {/* Body */}
        <div className="card-body pt-4">
          <div className="timeline timeline-6 mt-3 ml-5">
            {logs.map(log => (
              <div className="timeline-item align-items-start" >
                <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                  {moment(log.created_at).format("MM-DD-Y hh:mm")}
                </div>

                <div className="timeline-badge">
                  <i className="fa fa-genderless text-success icon-xl"></i>
                </div>

                <div className="timeline-content d-flex">
                  <span className="font-weight-bolder text-dark-75 pl-3 font-size-lg">
                  {t('call')} {log.event_type}
                  </span>
                  <div className="font-weight-mormal font-size-lg timeline-content text-muted pl-3">
                  {t('by')} {log.user_id}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}